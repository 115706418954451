import { FC, SVGAttributes, memo } from 'react';

// icons
import { ReactComponent as Heart } from 'src/assets/icons/heart.svg';
import { ReactComponent as Basket } from 'src/assets/icons/shop-basket.svg';
import { ReactComponent as Cross } from 'src/assets/icons/cross.svg';
import { ReactComponent as Sort } from 'src/assets/icons/sort.svg';
import { ReactComponent as Info } from 'src/assets/icons/info.svg';
import { ReactComponent as ChevronRight } from 'src/assets/icons/chevron-right.svg';
import { ReactComponent as Filter } from 'src/assets/icons/filter.svg';
import { ReactComponent as ArrowTopRight } from 'src/assets/icons/arrow-top-right.svg';
import { ReactComponent as Share } from 'src/assets/icons/share.svg';
import { ReactComponent as NoImage } from 'src/assets/svg/no-image.svg';

// icons-clear
import { ReactComponent as EmptyCart } from 'src/assets/icons-clear/empty-cart.svg';
import { ReactComponent as CrossClear } from 'src/assets/icons-clear/cross.svg';
import { ReactComponent as Check } from 'src/assets/icons-clear/check.svg';
import { ReactComponent as Guard } from 'src/assets/icons-clear/guard.svg';
import { ReactComponent as Shop } from 'src/assets/icons-clear/shop.svg';
import { ReactComponent as SearchClear } from 'src/assets/icons-clear/search.svg';

export type IconList =
  | 'heart'
  | 'basket'
  | 'cross'
  | 'sort'
  | 'filter'
  | 'info'
  | 'chevron-right'
  | 'empty-cart'
  | 'arrow-top-right'
  | 'guard'
  | 'check'
  | 'cross-clear'
  | 'shop'
  | 'share'
  | 'search-clear'
  | 'no-image'

interface IProps {
  name: IconList;
  properties?: SVGAttributes<unknown>;
}

export const Icon: FC<IProps> = memo(function Icon(props) {
  const { name, properties = {} } = props;
  switch (name) {
    case 'heart':
      return <Heart {...properties} />;
    case 'basket':
      return <Basket {...properties} />;
    case 'cross':
      return <Cross {...properties} />;
    case 'sort':
      return <Sort {...properties} />;
    case 'filter':
      return <Filter {...properties} />;
    case 'info':
      return <Info {...properties} />;
    case 'chevron-right':
      return <ChevronRight {...properties} />;
    case 'empty-cart':
      return <EmptyCart {...properties} />;
    case 'arrow-top-right':
      return <ArrowTopRight {...properties} />;
    case 'guard':
      return <Guard {...properties} />;
    case 'check':
      return <Check {...properties} />;
    case 'cross-clear':
      return <CrossClear {...properties} />;
    case 'shop':
      return <Shop {...properties} />;
    case 'share':
      return <Share {...properties} />
    case 'search-clear':
      return <SearchClear {...properties} />
    case 'no-image':
      return <NoImage {...properties} />;
  }
});
