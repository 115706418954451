import { FC, memo, useEffect } from 'react';
import { Layout } from 'src/components/Layout/Layout';
import { useStyle } from 'src/utils/theme/useStyle';
import useLoader from 'src/hooks/useLoader';
import Text from 'src/components/UI/Text/Text';
import Container from 'src/components/Container/Container';
import { MakeOrderRules } from '../MakeOrder/MakeOrder.style';
import { MakeOrderForm } from 'src/components/MakeOrderForm/MakeOrderForm';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { getProductDetail, productState } from 'src/redux/slices/productDetailSlice';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import { useNavigate, useParams } from 'react-router-dom';
import useBackButton from 'src/hooks/useBackButton';
import { checkoutFormGet, checkoutState } from 'src/redux/slices/checkoutSlice';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';

export const MakeSingleOrder: FC = memo(function MainPage() {
  const { css } = useStyle(MakeOrderRules);
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const { productId } = useParams();
  const { showLoader, hideLoader } = useLoader();
  const dispatch = useAppDispatch();
  const { product, needRedirectToProductPage, networkStatus } = useAppSelector(productState);
  const { checkoutForm, formNetworkStatus } = useAppSelector(checkoutState)
  const { t } = useTranslation();

  useEffect(() => {
    if (needRedirectToProductPage) {
      navigate(`/product/${productId}`);
    }
  }, [navigate, productId, needRedirectToProductPage]);

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate(-1);
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  useEffect(() => {
    if (formNetworkStatus === NetworkStatus.None) {
      dispatch(checkoutFormGet());
    }
  }, [dispatch, formNetworkStatus]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.Loading || formNetworkStatus === NetworkStatus.Loading) {
      showLoader();
    }
    if (networkStatus === NetworkStatus.Done && formNetworkStatus === NetworkStatus.Done) {
      hideLoader();
    }
  }, [networkStatus, hideLoader, showLoader, formNetworkStatus]);

  useEffect(() => {
    dispatch(getProductDetail(productId));
  }, [dispatch, productId]);

  return (
    <Layout
      header={
        <Container extend={css.header} paddingTop={20} paddingBottom={10}>
          <Text text={t('makeSingleOrder.checkout')} mod="title"/>
        </Container>
      }
    >
      {
        formNetworkStatus !== NetworkStatus.Loading && formNetworkStatus !== NetworkStatus.None && (
          <MakeOrderForm
            customerInitialData={{
              name: checkoutForm?.checkoutForm.value?.fullName,
              phone: checkoutForm?.checkoutForm.value?.phone,
              email: checkoutForm?.checkoutForm.case === 'digital'? checkoutForm?.checkoutForm.value?.email : ''
            }}
            product={{ price: Number(product?.price.amount), uuid: product?.uuid }}
          />
        )
      }
    </Layout>
  );
});
