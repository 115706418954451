import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IModalErrorOrSuccessInfoRuleKeys {
  iconWrapper: {};
  guard: {};
  icon: {};
  title: {};
}

export type IModalErrorOrSuccessInfoRules = IRuleFn<
  IModalErrorOrSuccessInfoRuleKeys
>;

const ModalErrorOrSuccessInfoRuleFn: IModalErrorOrSuccessInfoRules = () => {

  return {
    iconWrapper: {
      flexShrink: 0,
      marginBottom: '30px',
      position: 'relative',
      width: 66,
      height: 83,
    },
    guard: {
      position: 'absolute',
      left: 0,
      top: 0,
    },
    icon: {
      width: 30,
      height: 30,
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    title: {
      marginBottom: 8,
    },
  };
};

export const ModalErrorOrSuccessInfoRules = createCachedStyle(ModalErrorOrSuccessInfoRuleFn);
