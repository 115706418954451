import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IFavouritesRuleKeys {
  container: {};
  header: {};
  wrapperProducts: {};
  noFavourites: {};
  title: {};
  subtitle: {};
}

export type IFavouritesRules = IRuleFn<IFavouritesRuleKeys>;

const favouritesRuleFn: IFavouritesRules = () => {
  const headerHeight = 56;

  return {
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      padding: '16px 0',
      display: 'flex',
      alignItems: 'center',
      height: headerHeight,
    },
    wrapperProducts: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      columnGap: '8px',
      rowGap: '16px',
    },
    noFavourites: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    title: {
      textAlign: 'center',
      opacity: '.5',
      marginBottom: 8,
    },
    subtitle: {
      textAlign: 'center',
      opacity: '.5',
      lineHeight: '16px',
    },
  };
};

export const favouritesRules = createCachedStyle(favouritesRuleFn);
