import {
  Category,
  ClientCatalogProductListResponse,
  Filter,
} from '@teleport/schemas-protobuf';
import { IGetProductsListParams } from '../api/getProductsList';

export const ProductsListTranslator = {
  fromProductsResponse(response: ClientCatalogProductListResponse) {
    return response.products;
  },

  toRequestProductsByCategory(categoryId: string) {
    const categoryForFilter = new Category({
      // why title is required property ?
      type: { case: 'rootCategory', value: { title: 'request', uuid: categoryId } },
    });

    const filter = new Filter({
      filterDefaultState: { value: { category: categoryForFilter }, case: 'categoryFilter' },
    });

    const requestParams: IGetProductsListParams = {
      filters: [filter],
      sorting: 0,
    };

    return requestParams;
  },

  toRequestProductsBySubCategory(categoryUuid: string, subCategoryUuid: string) {
    const subCategoryForFilter = new Category({
      type: {
        case: 'subCategory',
        value: { categoryUuid, title: 'request', uuid: subCategoryUuid },
      },
    });

    const filter = new Filter({
      filterDefaultState: { value: { category: subCategoryForFilter }, case: 'categoryFilter' },
    });

    const requestParams: IGetProductsListParams = {
      filters: [filter],
      sorting: 0,
    };

    return requestParams;
  },

  toRequestProductsBySelection(selectionUuid: string) {
    const filter = new Filter({
      filterDefaultState: { value: { selectionUuid }, case: 'selectionFilter' },
    });
    const requestParams: IGetProductsListParams = {
      filters: [filter],
      sorting: 0,
    };
    return requestParams;
  },
};
