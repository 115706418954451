import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IStoreInfoKeys {
  wrapper: {},
  storeAvatar: {},
  description: {},
  name: {},
  info: {},
  skeletonTitle: {};
}

export type IStoreInfo = IRuleFn<IStoreInfoKeys>;

const StoreInfoRuleFn: IStoreInfo = props => {
  const { colorDarkGrey } = props.theme;
  const {} = props;

  return {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 10,
      maxWidth: '72%',
    },
    storeAvatar: {
      width: 38,
      height: 38,
      borderRadius: '50%',
      objectFit: 'cover'
    },
    description: {
      color: colorDarkGrey,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    name: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    info: {
      maxWidth: 'calc(100% - 48px)',
    },

    skeletonTitle: {
      marginBottom: 2,
    }
  }

}

export const StoreInfoRules = createCachedStyle(StoreInfoRuleFn);
