import { memo, useEffect, useMemo } from 'react';
import { Layout } from 'src/components/Layout/Layout';
import { useStyle } from 'src/utils/theme/useStyle';
import { useTheme } from 'src/utils/theme/useTheme';
import Text from 'src/components/UI/Text/Text';
import Container from 'src/components/Container/Container';
import ProductCard, { ProductCardSize } from 'src/components/ProductCard/ProductCard';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import { favouritesRules } from './favourities.style';
import useLoader from 'src/hooks/useLoader';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate } from 'react-router-dom';
import { favouritesState, getFavouritesProducts } from '../../redux/slices/favouritesSlice';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import ProductCardSkeleton from '../../components/ProductCard/ProdcutCardSkeleton';

const Favourites = function Favourites() {
  const { css } = useStyle(favouritesRules);
  const {} = useTheme();
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { products, networkStatus } = useAppSelector(favouritesState);

  useEffect(() => {
    dispatch(getFavouritesProducts());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate(-1);
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  const { hideLoader } = useLoader();

  useEffect(() => {
    hideLoader();
  }, [hideLoader]);

  const { t } = useTranslation()

  const content = useMemo(() => {
    if (networkStatus === NetworkStatus.Loading) {
      return (
        <div className={css.wrapperProducts}>
          <ProductCardSkeleton/>
          <ProductCardSkeleton/>
          <ProductCardSkeleton/>
          <ProductCardSkeleton/>
          <ProductCardSkeleton/>
          <ProductCardSkeleton/>
        </div>
      )
    } else if (products.length > 0) {
      return (
        <div className={css.wrapperProducts}>
          {products.map(product => (
            <ProductCard
              data={product}
              key={product.uuid}
              withSlider
              size={ProductCardSize.ADAPTIVE}
            />
          ))}
        </div>
      )
    } else {
      return (
        <div className={css.noFavourites}>
          <Text text={t('favourites.nothingHereYet')} textTransform="uppercase" mod="title" extend={css.title}/>
          <Text
            text={t('favourites.visitTheCatalogAndAddItemsToFavorites')}
            mod="text"
            extend={css.subtitle}
          />
        </div>
      )
    }
  }, [
    css,
    networkStatus,
    products,
    t
  ])

  return (
    <Layout
      footer={
        <Footer>
          <Button href="/catalog" text={t('favourites.catalog')} propsStyles={{width: '100%', height: 62}}/>
        </Footer>
      }
    >
      <Container extend={css.container}>
        <div className={css.header}>
          <Text text={t('favourites.favorites')} textTransform="uppercase" mod="title" />
        </div>
        {content}
      </Container>
    </Layout>
  );
};

export default memo(Favourites);
