import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ISelectedFiltersKeys {
  activeFilters: {};
}

export type ISelectedFilters = IRuleFn<ISelectedFiltersKeys>;

const SelectedFiltersRuleFn: ISelectedFilters = props => {
  const {} = props.theme;
  const {} = props;

  return {
    activeFilters: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
  };
};

export const SelectedFiltersRules = createCachedStyle(SelectedFiltersRuleFn);
