// TelegramProvider
// https://dev.to/bitofuniverse/telegram-web-app-with-react-and-nextjs-440i
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { ITelegramUser, IWebApp } from 'src/types/telegram';

export interface ITelegramContext {
  webApp?: IWebApp;
  user?: ITelegramUser;
}

export const TelegramContext = createContext<ITelegramContext>({});

export function TelegramProvider({ children }: { children: React.ReactNode }) {
  const [webApp, setWebApp] = useState<IWebApp | null>(null);

  useEffect(() => {
    const app = (window as any).Telegram?.WebApp;
    if (app) {
      app.ready();
      app.expand();
      app.enableClosingConfirmation();
      app.disableVerticalSwipes();
      setWebApp(app);
    }
  }, []);

  const value = useMemo(
    () =>
      webApp
        ? {
          webApp,
          unsafeData: webApp.initDataUnsafe,
          user: webApp.initDataUnsafe.user,
        }
        : {},
    [webApp],
  );

  return (
    <TelegramContext.Provider value={value}>
      {/* Make sure to include script tag with
      "beforeInteractive" strategy to pre-load web-app script */}
      {children}
    </TelegramContext.Provider>
  );
}

export const useTelegram = () => useContext(TelegramContext);
