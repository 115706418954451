import { FC, memo, useEffect } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { storeNoteWorkingRules } from './storeNotWorkingNoRedux.style';
import { Layout } from '../Layout/Layout';
import useLoader from 'src/hooks/useLoader';
import { Icon } from '../Icon/Icon';
import { ITranslation } from 'src/utils/i18n/i18n.interface';
import { useTheme } from 'src/utils/theme/useTheme';

interface IProps {
  reasonText: string;
  supportLink?: string;
  language: ITranslation
}

const StoreNotWorkingNoRedux: FC<IProps> = memo(function StoreNotWorkingNoRedux(props) {
  const { css } = useStyle(storeNoteWorkingRules);
  const { hideLoader } = useLoader();
  const { reasonText } = props;

  const { theme } = useTheme()

  useEffect(() => {
    hideLoader();
  }, [hideLoader]);

  

  return (
    <div className={css.app}>
      <Layout >
        <div className={css.wrapper}>
          <Icon name="shop" properties={{ className: css.img, fill: theme.mainColor }} />
          <span className={[css.text, css.title].join(' ')}>{true? 'The store is temporarily unavailable' : 'Магазин временно не работает'}</span>
          <span className={[css.text, css.subtitle].join(' ')}>{reasonText}</span>
        </div>
      </Layout>
    </div>
  );
});

export default StoreNotWorkingNoRedux;
