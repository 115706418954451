import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IWrapperRuleKeys {
  wrapper: {};
}

interface IWrapperRuleProps {
  backgroundColor: string;
  paddingBottom: string | number;
}

export type IWrapperRules = IRuleFn<IWrapperRuleKeys, IWrapperRuleProps>;

const wrapperRuleFn: IWrapperRules = props => {
  const { colorWhite } = props.theme;
  const { backgroundColor, paddingBottom = 12 } = props;

  return {
    wrapper: {
      paddingTop: 12,
      paddingBottom,
      backgroundColor: backgroundColor || colorWhite,
      ':first-of-type': {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
      },
    },
  };
};

export const wrapperRules = createCachedStyle(wrapperRuleFn);
