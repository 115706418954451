import { FC, memo } from 'react';
import { IClasses } from 'src/utils/theme/fela.interface';
import { useStyle } from 'src/utils/theme/useStyle';
import Text from 'src/components/UI/Text/Text';
import Chip from 'src/components/UI/Chip/Chip';
import { Info } from 'src/components/UI/Info/info';
import { Price } from 'src/components/Price/Price';
import { modificatorOptionsRules } from './modificatorOptions.style';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { changeChosenModifiers, productState, removeErrorModifiersUuid } from 'src/redux/slices/productDetailSlice';
import { ICompoundModifier } from 'src/blocks/Product/components/ProductModificators/ProductModificators';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

interface IProps {
  extend?: IClasses;
  modifier: ICompoundModifier
}

export const ModificatorOptions: FC<IProps> = memo(function ModificatorOptions(props) {
  const { css } = useStyle(modificatorOptionsRules);
  const { extend, modifier } = props;
  const dispatch = useAppDispatch()
  const { chosenModifiers, errorModifiersUuids } = useAppSelector(productState)

  const activeVariant = modifier.variants.find(a => chosenModifiers.some(b => b.uuid === a.uuid))?.uuid
  
  const { t } = useTranslation()  

  const affectPriceAmount = modifier.variants.find(variant => variant.uuid === activeVariant)?.priceImpact

  const onChangeModifier = event => {
    const value = event.currentTarget.value;
    const modifiertoSend = modifier.variants.find(el => el.uuid === value)
    // Сбрасываем ошибку для модификаторов
    dispatch(removeErrorModifiersUuid(modifier.uuid))

    dispatch(changeChosenModifiers({
      uuid: modifiertoSend.uuid,
      title: modifiertoSend.title,
      modifierUuid: modifier.uuid
    }))
  };

  return (
    <div className={[extend].join(' ')}>
      <div className={css.header}>
        <div className={css.info}>
          <Text
            text={modifier.title}
            mod="title"
            textTransform="uppercase"
            fontSize={12}
            lineHeight="18px"
          />
          {/* <span className={css.value}></span> */}
          {modifier.descriptionLink && <Info href={modifier.descriptionLink} />}
        </div>
        { affectPriceAmount > 0 && <Price amount={affectPriceAmount} isPositive hasSymbol fontWeight={700} fontSize={12} lineHeight={'18px'} />}
      </div>
      {
        modifier.variants.length > 0 && (
          <div className={css.options}>
            {
              modifier.variants.map(variant => {
                return (
                  <Chip
                    key={variant.uuid}
                    name="size"
                    text={variant.title}
                    value={variant.uuid}
                    type='checkbox'
                    checked={activeVariant === variant.uuid}
                    onChange={onChangeModifier}
                  />
                )
              })
            }
          </div>
        )
      }
      {errorModifiersUuids.includes(modifier.uuid) && <p className={css.error}>{t('modificatorOptions.selectFromTheOptions')}</p>}
    </div>
  );
});
