import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IProductPageKeys {
  productInner: {};
  wrapperInfo: {};
  wrapperCollections: {};
  skeletonProductModifier: {};
  skeletonProductModifierTop: {};
  skeletonProductModifierContent: {};
}

export type IProductPages = IRuleFn<IProductPageKeys>;

const productPageRuleFn: IProductPages = () => {
  return {
    productInner: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    wrapperInfo: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      rowGap: '20px',
    },
    wrapperCollections: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '32px',
    },

    skeletonProductModifier: {
      paddingTop: 17,
    },
    skeletonProductModifierTop: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 8,
    },
    skeletonProductModifierContent: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
    },
  };
};

export const productPageRules = createCachedStyle(productPageRuleFn);
