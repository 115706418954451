import { Interceptor } from '@connectrpc/connect';
import { DEV_TELEGRAM_INIT } from 'src/utils/constants';

function getAuthorizationHeader() {
  if (window.Telegram.WebApp.initData) {
    return `tma ${window.Telegram.WebApp.initData}`;
  }
  return `tma ${DEV_TELEGRAM_INIT ?? ''}`;
}

export const headersAuthInterceptor: Interceptor = next => async request => {
  const authorizationHeader = getAuthorizationHeader();
  request.header.set('Authorization', authorizationHeader);
  return await next({
    ...request,
    header: request.header,
  });
};
