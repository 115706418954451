import { Filter, Sorting } from '@teleport/schemas-protobuf';
import { createAppAsyncThunk } from '../../hooks/redux';

export interface IGetProductsListParams {
  filters: Filter[];
  sorting: Sorting;
}

export const getProductsList = createAppAsyncThunk(
  'productList',
  async (params: IGetProductsListParams, thunkAPI) => {
    return await thunkAPI.extra.portApi.clientCatalogProductList(params);
  },
);
