import { useEffect } from 'react';
import useLoader from 'src/hooks/useLoader';

const NotFoundPage = function NotFoundPage() {
  const { hideLoader } = useLoader();

  useEffect(() => {
    hideLoader();
  }, [hideLoader]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <h1 style={{ textAlign: 'center' }}>404 Error</h1>
    </div>
  );
};

export default NotFoundPage;
