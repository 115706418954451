import { memo, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { rangeSliderRules } from './rangeSlider.style';
import useCurrency from 'src/hooks/useCurrency';
import { telegramStarsString } from 'src/utils/constants';

interface IProps {
  min: number;
  max: number;
  presentMinValue?: number;
  presentMaxValue?: number;
  step: number;
  mod?: 'price';
  extend?: string;
  name: string;
  attributes?: Record<string, string>;
  changeMinValue?: (event) => void;
  changeMaxValue?: (event) => void;
}

const RangeSlider = function RangeSlider(props: IProps) {
  const {
    max,
    min,
    presentMinValue,
    presentMaxValue,
    step,
    mod,
    extend,
    name,
    attributes,
    changeMinValue,
    changeMaxValue,
  } = props;
  const { css } = useStyle(rangeSliderRules);

  const currency= useCurrency()
  const isTelegramCurrency = currency === telegramStarsString

  const [minValue, setMinValue] = useState(presentMinValue || min);
  const [maxValue, setMaxValue] = useState(presentMaxValue || max);

  const handleMinChange = event => {
    event.preventDefault();
    const value = parseFloat(event.target.value);

    const newMinVal = Math.min(value, maxValue - step);
    setMinValue(newMinVal);

    changeMinValue && changeMinValue(event);
  };

  const handleMaxChange = event => {
    event.preventDefault();
    const value = parseFloat(event.target.value);

    const newMaxVal = Math.max(value, minValue + step);
    setMaxValue(newMaxVal);

    changeMaxValue && changeMaxValue(event);
  };

  const minPos = ((minValue - min) / (max - min)) * 100;
  const maxPos = ((maxValue - min) / (max - min)) * 100;
  

  return (
    <div className={extend}>
      {mod === 'price' && (
        <div className={css.priceBlock}>
          <p className={css.priceRow}>
            от {minValue}
            <span>{isTelegramCurrency? <img style={{width: 16}}  src={`/images/currencies/${currency}.svg`} alt='' /> : currency }</span>
          </p>
          <p className={css.priceRow}>
            до {maxValue}
            <span>{isTelegramCurrency? <img style={{width: 16}}  src={`/images/currencies/${currency}.svg`} alt='' /> : currency }</span>
          </p>
        </div>
      )}
      <div className={css.rangeSlider}>
        <div className={css.inputWrapper}>
          <input
            type="range"
            value={minValue}
            name={`range-min-${name}`}
            min={min}
            max={max}
            step={step}
            onChange={handleMinChange}
            className={`preventDefault ${css.input}`}
            {...attributes}
          />
          <input
            type="range"
            value={maxValue}
            name={`range-max-${name}`}
            min={min}
            max={max}
            step={step}
            onChange={handleMaxChange}
            className={`preventDefault ${css.input}`}
            {...attributes}
          />
        </div>
        <div className={css.controller}>
          <div className={css.control} style={{ left: `${minPos}%` }} />
          <div className={css.rail}>
            <div
              className={css.railInner}
              style={{ left: `${minPos}%`, right: `${100 - maxPos}%` }}
            />
          </div>
          <div className={css.control} style={{ left: `${maxPos}%` }} />
        </div>
      </div>
    </div>
  );
};

export default memo(RangeSlider);
