import { useStyle } from '../../utils/theme/useStyle';
import { InfoblockRules } from './infoBlock.style';
import { SkeletonText } from '../UI/SkeletonText/SkeletonText';

export const InfoBlockSkeleton = function InfoBlockSkeleton() {
  const { css, rules } = useStyle(InfoblockRules, { accentColor: false });

  return (
    <div className={css.wrapper}>
      <SkeletonText width={'60%'} height={32} extend={{root: rules.skeletonTitle}} />
      <div className={css.content}>
        <SkeletonText width={'100%'} height={80} />
      </div>
    </div>
  );
};
