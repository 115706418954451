import {
  ChangeEvent,
  ReactNode,
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { inputRules } from './input.style';
import Text from '../Text/Text';
import { Icon, IconList } from 'src/components/Icon/Icon';

interface CommonProps {
  name: string;
  type: string;
  id?: string;
  label?: string;
  message?: ReactNode;
  placeholder?: string;
  maxLength?: number;
  letterCounter?: boolean;
  symbol?: string;
  propsStyles?: IStyles;
  iconBtn?: IconList;
  errorMessage?: string;
  onIconBtnClick?: (event: string | number) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  labelUppercase?: 'uppercase' | 'none' | 'capitalize';
}

type ConditionalProps =
  | {
      controlled: true;
      value: string;
      onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    }
  | { controlled: false; value?: never; onChange?: (event: ChangeEvent<HTMLInputElement>) => void };

type IProps = CommonProps & ConditionalProps;

interface IStyles {
  backgroundColor: string;
}

export const Input = memo(
  forwardRef<HTMLInputElement, IProps>(function Input(props, ref) {
    const {
      id,
      name,
      type,
      controlled,
      value = '',
      placeholder,
      letterCounter,
      label,
      maxLength = 100,
      propsStyles,
      message,
      errorMessage,
      symbol,
      iconBtn,
      onChange,
      onFocus,
      onBlur,
      onIconBtnClick,
      labelUppercase,
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);
    const [currentValue, setCurrentValue] = useState(value);
    const [valid, setValid] = useState({ valid: true, message: '' });

    useImperativeHandle(ref, () => inputRef.current, []);

    useEffect(() => {
      if (errorMessage) {
        setValid({ valid: false, message: errorMessage });
      } else {
        setValid({ valid: true, message: '' });
      }
    }, [errorMessage]);

    useEffect(() => {
      const input = inputRef.current;

      if (!input) return null;

      setCurrentValue(input.value);

      function listenInputKey(event) {
        if (event.key === 'Enter') {
          input.blur();
        }
      }

      function stopBrowserSubmit(event) {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      }

      input.addEventListener('keypress', stopBrowserSubmit);
      input.addEventListener('keyup', listenInputKey);
      return () => {
        input.removeEventListener('keyup', listenInputKey);
        input.removeEventListener('keypress', stopBrowserSubmit);
      };
    }, [inputRef]);

    const { css } = useStyle(inputRules, {
      backgroundColor: propsStyles?.backgroundColor,
      isInvaild: !valid.valid,
      hasSymbol: Boolean(symbol) || Boolean(iconBtn),
    });

    function onChangeValue(event: ChangeEvent<HTMLInputElement>) {
      // ! Не подчёркиваются красным необязательные props. Не работает ts Cannot invoke an object which is possibly 'undefined'
      if (!errorMessage) setValid({ valid: true, message: '' });
      setCurrentValue(event.target.value);
      onChange && onChange(event);
    }

    function handleFocus(event: React.FocusEvent<HTMLInputElement, Element>) {
      const input = event.currentTarget;
      // fix safari open keyboard scroll
      input.style.opacity = '0';
      setTimeout(() => (input.style.opacity = '1'));
      // fix carret position
      if (type === 'string') {
        setTimeout(() => {
          input.selectionStart = input.selectionEnd = 10000;
        }, 0);
      }
      onFocus && onFocus(event);
    }

    const onIconClickFunction = () => {
      onIconBtnClick && onIconBtnClick(currentValue);
    };

    return (
      <div>
        {(label || letterCounter) && (
          <div className={css.top}>
            {label && (
              <label>
                <Text
                  text={label}
                  mod="text"
                  textTransform={labelUppercase ? labelUppercase : 'auto'}
                  fontSize={12}
                />
              </label>
            )}
            {letterCounter && (
              <Text mod="text" text={`${currentValue.length}/${maxLength}`} fontSize={12} />
            )}
          </div>
        )}

        <div className={`${css.inputWrapper} inputWrapper`}>
          {controlled ? (
            <input
              id={id}
              name={name}
              value={value}
              placeholder={placeholder}
              type={type}
              className={css.input}
              maxLength={maxLength}
              ref={inputRef}
              onChange={onChangeValue}
              onFocus={handleFocus}
              onBlur={onBlur}
              enterKeyHint={'done'}
            />
          ) : (
            <input
              id={id}
              name={name}
              placeholder={placeholder}
              type={type}
              className={css.input}
              maxLength={maxLength}
              ref={inputRef}
              onChange={onChangeValue}
              onFocus={handleFocus}
              onBlur={onBlur}
              enterKeyHint={'done'}
            />
          )}

          {symbol && !iconBtn && (
            <Text
              text={symbol}
              mod="title"
              fontWeight={700}
              fontSize={16}
              lineHeight="18px"
              extend={css.symbol}
            />
          )}
          {iconBtn && !symbol && (
            <div onClick={onIconClickFunction} className={css.iconBtn}>
              <Icon name={iconBtn} />
            </div>
          )}
        </div>
        {(valid.message || message) && (
          <p className={css.message}>{valid.message ? valid.message : message}</p>
        )}
      </div>
    );
  }),
);
