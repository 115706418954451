import { useStyle } from '../../utils/theme/useStyle';
import { advertisingRules } from './advertising.style';
import { SkeletonImage } from '../UI/SkeletonImage/SkeletonImage';

export const AdvertisingSkeleton = function AdvertisingSkeleton() {
  const { css } = useStyle(advertisingRules, {
    marginBottom: 0,
  });

  return (
    <div className={css.skeletonWrapper}>
      <SkeletonImage width={344} height={344} borderRadius="16px" />
      <SkeletonImage width={344} height={344} borderRadius="16px" />
      <SkeletonImage width={344} height={344} borderRadius="16px" />
    </div>
  )
};
