import { ClientModifier } from '@teleport/schemas-protobuf';
import { createAppAsyncThunk } from '../../hooks/redux';
import {CheckoutTranslator} from '../translators/checkoutTranslator';

export interface IDirectCheckoutParams {
  product: {
    productUuid: string,
    quantity: number,
    chosenModifiers: ClientModifier[]
  };
  formData: {
    name: string,
    phone: string,
    email?: string,
  }
}

export const directCheckout = createAppAsyncThunk('checkout/directCheckout', async (params: IDirectCheckoutParams, thunkAPI) => {
  const state = thunkAPI.getState();
  const deliveryType = state.config.config.deliveryType;
  const requestData = CheckoutTranslator.toCheckoutRequest(params, deliveryType);
  return await thunkAPI.extra.portApi.directCheckout(requestData);
});


