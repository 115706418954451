import { memo } from 'react';
import { SkeletonImage } from '../UI/SkeletonImage/SkeletonImage';
import { SkeletonText } from '../UI/SkeletonText/SkeletonText';
import { useStyle } from '../../utils/theme/useStyle';
import { cartitemRules } from './cartitem.style';

export const CartItemSkeleton = memo(function CartItemSkeleton() {
  const { css, rules } = useStyle(cartitemRules, { isFavorite: false });

  return (
    <div className={`${css.ci_wrp} ${css.skeletonCard}`}>
      <SkeletonImage width={100} height={100} borderRadius={'8px'} />
      <div className={css.info}>
        <div>
          <SkeletonText width={'100%'} height={16} extend={{ root: rules.skeletonTitle }} />
          <SkeletonText width={'60%'} height={16} />
        </div>
        <div className={css.info_bottom}>
          <SkeletonText width={90} height={24} />
          <SkeletonText width={50} height={22} />
        </div>
      </div>
    </div>
  )
});

export default CartItemSkeleton;
