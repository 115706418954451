import { createAppAsyncThunk } from '../../../hooks/redux';
import type { CartProduct } from '@teleport/schemas-protobuf/port/v1/port_order_pb';

export const removeProductFromCart = createAppAsyncThunk('cart/removeProductFromCart', async (args: {products: CartProduct[], deletedUuid: string, id: string}, thunkAPI) => {
  const { products } = args;
  const result = await thunkAPI.extra.portApi.cartUpsert({ products });
  return {
    total: result.totalV2,
    ...args,
  }
});
