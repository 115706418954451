import { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import { useStyle } from 'src/utils/theme/useStyle';
import { infoRules } from './info.style';
import { Icon } from 'src/components/Icon/Icon';

interface IProps {
  href: string;
}

export const Info: FC<IProps> = memo(function Info(props) {
  const { href } = props;
  const { css } = useStyle(infoRules);
  return (
    <Link to={href} className={css.info}>
      <Icon name="info" />
    </Link>
  );
});
