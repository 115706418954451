import { ReactNode, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { IClasses } from 'src/utils/theme/fela.interface';
import { wrapperRules } from './wrapper.style';

interface IProps {
  propsStyles?: IStyles;
  children: ReactNode;
  extend?: IClasses;
}

interface IStyles {
  backgroundColor?: string;
  paddingBottom?: string | number;
}

const Wrapper = function Wrapper(props: IProps) {
  const { propsStyles = {}, children, extend } = props;

  const { css } = useStyle(wrapperRules, {
    backgroundColor: propsStyles?.backgroundColor,
    paddingBottom: propsStyles?.paddingBottom,
  });

  return <section className={[css.wrapper, extend].join(' ')}>{children}</section>;
};

export default memo(Wrapper);
