import { render } from './Render';
import { getConfig } from './utils/config/getConfig';
import { getTranslation } from './utils/i18n/getTranslation';
import { Language } from './utils/i18n/i18n.interface';

export async function init() {
  const config = await getConfig();
  const translation = config? await getTranslation(Language[config.language]) : undefined;
  render(config, translation);
}
