import { register } from 'swiper/element/bundle';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { routeConfig } from './providers/AppRouter/routeConfig';
import { useStyle } from './utils/theme/useStyle';
import { appRules } from './App.style';
import 'swiper/css';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from './hooks/redux';
import { closeErrorModal, modalErrorState } from './redux/slices/modalErrorSlice';
import { useTranslation } from './utils/i18n/hooks/useTranslation';
import { useModalState } from './hooks/useModalState';
import ModalError from './components/ModalError/ModalError';

// swiper register
register();

const router = createBrowserRouter(routeConfig);

function App() {
  const { css } = useStyle(appRules);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const { modalErrorIsOpen, errorTitle, errorText } = useAppSelector(modalErrorState);
  const [contentError, setContentError] = useState({
    title: t('layout.somethingWentWrong'),
    text: ''
  });

  const customCloseModal = useCallback(() => {
    dispatch(closeErrorModal());
    closeModal();
  }, [dispatch, closeModal]);

  useEffect(() => {
    if(modalErrorIsOpen) {
      setContentError({
        title: errorTitle ? errorTitle : t('layout.somethingWentWrong'),
        text: errorText
      })
      openModal();
    }
  }, [
    t,
    openModal,
    modalErrorIsOpen,
    errorTitle,
    errorText
  ]);

  return (
    <div className={css.app}>
      <RouterProvider router={router} />
      {renderModal && (
        <ModalError
          onClose={customCloseModal}
          title={contentError.title}
          text={contentError.text}
          active={activeModal}
        />
      )}
    </div>
  );
}

export default App;
