import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from '../../utils/applyCSSProperty';

export interface IStoriesRuleKeys {
  wrapper: {};
  slider: {};
  slide: {};
  skeletonWrapper: {};
  skeletonCard: {};
}

interface IStoriesRuleProps {
  paddingTop;
  paddingBottom;
}

export type IStoriesRules = IRuleFn<IStoriesRuleKeys, IStoriesRuleProps>;

const storiesRuleFn: IStoriesRules = props => {
  const { paddingTop, paddingBottom } = props;

  return {
    wrapper: {
      paddingTop,
      paddingBottom,
    },
    slider: {
      '& .swiper-wrapper': {
        padding: '0 20px',
      },
    },
    slide: {
      width: '100px',
    },
    skeletonWrapper: {
      overflow: 'hidden',
      ...padding(8, 20, 20),
      display: 'flex',
      columnGap: 8,
    },
    skeletonCard: {
      flexShrink: 0,
    }
  };
};

export const storiesRules = createCachedStyle(storiesRuleFn);
