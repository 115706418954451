import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IOptionCardRuleKeys {
  card: {};
  input: {};
  text: {};
}

interface IOptionCardProps {
  checked: boolean;
}

export type IOptionCardRules = IRuleFn<IOptionCardRuleKeys, IOptionCardProps>;

const optionCardRuleFn: IOptionCardRules = props => {
  const { mainColor, background } = props.theme;
  const { checked } = props;
  return {
    card: {
      display: 'flex',
      columnGap: 4,
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '16px',
      borderRadius: '12px',
      backgroundColor: background,
      borderStyle: 'solid',
      borderWidth: '2px',
      borderColor: checked? mainColor :'transparent',

    },
    input: {
      position: 'absolute',
      zIndex: '-1',
      opacity: 0,
    },
    text: {},
  };
};

export const optionCardRules = createCachedStyle(optionCardRuleFn);
