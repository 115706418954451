import { FC, memo } from 'react';
import { useStyle } from '../../../utils/theme/useStyle';
import { ISkeletonTextRuleKeys, skeletonTextRules } from './SkeletonText.style';

interface IProps {
  width: number | string;
  height: number | string;
  extend?: Partial<ISkeletonTextRuleKeys>;
}

export const SkeletonText: FC<IProps> = memo(function SkeletonText(props) {
  const { width,  height, extend } = props;
  const { css } = useStyle(skeletonTextRules, {
    width,
    height,
    extend
  });

  return (
    <div className={css.root}></div>
  );
});
