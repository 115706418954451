import { ClientModifier } from '@teleport/schemas-protobuf';
import { createAppAsyncThunk } from '../../../hooks/redux';
import { CartTranslator } from '../../translators/cartTranslator';

export interface IProductToCardParams {
  productUuid: string;
  chosenModifiers: ClientModifier[]
}

export const productToCardAppend = createAppAsyncThunk('cart/productToCardAppend', async (params: IProductToCardParams, thunkAPI) => {
  const product = CartTranslator.toProductToCardAppendRequest(params);
  await thunkAPI.extra.portApi.productToCartAppend({ product });
  return product;
});
