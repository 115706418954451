import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store';
import { PromiseClient } from '@connectrpc/connect/dist/cjs/promise-client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PortClientService } from '@teleport/schemas-protobuf';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  extra: {
    portApi: PromiseClient<typeof PortClientService>;
  };
}>();
