import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IFavoriteButtonKeys {
  button: {};
}

interface IFavoriteButtonProps {
  isFavorite: boolean;
}

export type IFavoriteButton = IRuleFn<IFavoriteButtonKeys, IFavoriteButtonProps>;

const FavoriteButtonRuleFn: IFavoriteButton = props => {
  const { colorBlack, colorWhite } = props.theme;
  const { isFavorite } = props;

  return {
    button: {
      width: 24,
      height: 24,
      padding: 4,
      backgroundColor: colorWhite,
      borderRadius: '50%',
      border: 'none',
      outline: 'none',

      '& svg': {
        flexShrink: 0,
        width: 16,
        height: 16,
        fill: isFavorite ? colorBlack : 'transparent',

        '& .border': {
          fill: colorBlack,
        },
      },
    },
  };
};

export const FavoriteButtonRules = createCachedStyle(FavoriteButtonRuleFn);
