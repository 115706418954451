import { memo, useEffect, useRef } from 'react';
import { useTheme } from 'src/utils/theme/useTheme';
import { useStyle } from 'src/utils/theme/useStyle';
import { Banner } from '../Banner/Banner';
import { advertisingRules } from './advertising.style';

interface Slide {
  imageUrl: string;
  clickUrl: string;
}

interface IProps {
  className?: string;
  propsStyles?: IStyles;

  slides: Slide[];
}

export interface IStyles {
  marginBottom?: number;
}

const Advertising = function Advertising(props: IProps) {
  const { propsStyles, slides } = props;
  const { css } = useStyle(advertisingRules, {
    marginBottom: propsStyles?.marginBottom,
  });
  const sliderRef = useRef<any>(null);
  const { theme } = useTheme();

  // swiper settings
  useEffect(() => {
    const swiperEl = sliderRef.current;

    if (!swiperEl) return;

    const swiperParams = {
      spaceBetween: 8,
      slidesPerView: 'auto',
      centeredSlides: true,
      pagination: {
        type: 'bullets',
        bulletClass: 'custom-bullet',
        bulletActiveClass: 'active',
      },
      injectStyles: [
        `.swiper {
          overflow-x: auto;
          scrollbar-width: none;
        }`,
        `.swiper::-webkit-scrollbar: {
          display: none;
        }`,
        `.custom-bullet {
          display: block; 
          width: 4px; 
          height: 4px; 
          border-radius: 50%;
          background: ${theme.colorGrey};          
        }`,
        `.custom-bullet:only-child {
          display: none!important;
        }`,
        `.active {
          background: ${theme.colorBlack};
        }`,
        `.swiper-pagination {
          position: relative; 
          display: flex; 
          justify-content: center; 
          column-gap: 4px; 
          bottom: 0!important; 
          margin-top: 4px}`,
        'pagination: ',
      ],
      // Не смог подключить стили через файл
      // injectStylesUrls: ["../../../styles/global.css"],
    };

    Object.assign(swiperEl, swiperParams);

    swiperEl.initialize();
  }, [theme.colorGrey, theme.colorBlack]);

  return (
    <swiper-container
      ref={sliderRef}
      // @ts-ignore:next-line
      class={css.slider}
      init={false}
    >
      {slides.map((slide, index) => (
        // @ts-ignore:next-line
        <swiper-slide class={css.slide} key={index}>
          <Banner image={slide.imageUrl} url={slide.clickUrl} />
        </swiper-slide>
      ))}
    </swiper-container>
  );
};

export default memo(Advertising);
