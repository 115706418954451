import { ClientCatalogProductDetailResponse } from '@teleport/schemas-protobuf';


export const ProductTranslator = {
  fromStatusResponse(response: ClientCatalogProductDetailResponse) {
    return response.product;
    // return {
    //   ...response.product,
    //   price: {
    //     amount: Number(response.product.price.amount),
    //     discount: response.product.price.discountEnabled,
    //     discountInPercent: response.product.price.discountInPercent,
    //     oldAmount: Number(response.product.price.oldAmount),
    //   },
    // };
  },
};
