import { ICartProductWidthId } from 'src/redux/slices/cartSlice';
import { createAppAsyncThunk } from '../../../hooks/redux';
import { CartPrice } from '@teleport/schemas-protobuf/port/v1/port_order_pb';
import { Promocode } from '@teleport/schemas-protobuf';

export const cartGet = createAppAsyncThunk('cart/cartGet', async (_, thunkAPI): Promise<{products: ICartProductWidthId[], total: CartPrice, promocode: Promocode}> => {
  const cartGetResponse = await thunkAPI.extra.portApi.cartGet({});
  const cartProducts = cartGetResponse.products;
  const products = cartProducts.map(product => {
    const cartProduct: ICartProductWidthId = {
      id:  `${product.productUuid}-${product.chosenModifiers.map(modifier => modifier.modifierVariantUuid.slice(0, 4)).join('-')}`,
      productUuid: product.productUuid,
      quantity: product.quantity,
      chosenModifiers: product.chosenModifiers,
      productTitle: product.productTitle,
      price: product.price,
      productImageUrl: product.productImageUrl,
      productIsFavorite: product.productIsFavorite,
    }
    return cartProduct
  });
  return {
    products: products,
    total: cartGetResponse.totalV2,
    promocode: cartGetResponse.promocode
  };
});

