import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { UIBlock } from '@teleport/schemas-protobuf';
import { MainTranslator } from '../translators/mainTranslator';
import { NetworkStatus } from '../../utils/connect/connectConstant';
import { createAppAsyncThunk } from '../../hooks/redux';

interface MainState {
  blocks: UIBlock[];
  hasMaintenance: boolean;
  maintenance: {
    reasonText: string;
    supportLink: string;
  };
  networkStatus: NetworkStatus;
}

const initialState: MainState = {
  blocks: [],
  hasMaintenance: false,
  maintenance: {
    reasonText: undefined,
    supportLink: undefined,
  },
  networkStatus: NetworkStatus.None,
};

export const getStatus = createAppAsyncThunk('main/status', async (_, thunkAPI) => {
  const result = await thunkAPI.extra.portApi.status({});

  return MainTranslator.fromStatusResponse(result);
});

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getStatus.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getStatus.fulfilled, (state, action) => {
      const { blocks, hasMaintenance, maintenance } = action.payload;
      state.hasMaintenance = true;
      state.maintenance = maintenance;
      state.blocks = blocks;
      state.hasMaintenance = hasMaintenance;
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getStatus.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });
  },
});

export const {} = mainSlice.actions;
export const mainState = (state: RootState) => state[mainSlice.name];
