import { ChangeEvent, FC, memo } from 'react';
import { Price } from 'src/components/Price/Price';
import { useStyle } from 'src/utils/theme/useStyle';
import { IModificatorListValue } from 'src/types/modificators';
import { optionCardRules } from './OptionCard.style';
import Text from '../../../../UI/Text/Text';

interface IProps {
  type: 'radio' | 'checkbox';
  name: string;
  value: string;
  selectedValue: string;
  text: string;
  price?: number;
  onChange: (value: IModificatorListValue) => void;
}

export const OptionCard: FC<IProps> = memo(function OptionCard(props) {
  const { text, name, type, value, selectedValue, onChange, price } = props;
  const { css } = useStyle(optionCardRules, {checked: selectedValue === value});

  const changeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (price) {
      onChange({ value, price });
    } else {
      onChange({ value });
    }
  };

  return (
    <label className={css.card}>
      <input
        className={css.input}
        name={name}
        onChange={changeValue}
        type={type}
        value={value}
        checked={selectedValue === value}
      />
      <Text text={text} mod="text" fontWeight={700} />
      {price && <Price amount={price} hasSymbol isPositive fontWeight={700} fontSize={12} lineHeight={'18px'}/>}
    </label>
  );
});
