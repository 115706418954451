import { useTelegram } from 'src/utils/telegramProvider';

const useBackButton = () => {
  const Telegram = useTelegram();
  const BackButton = Telegram?.webApp?.BackButton;

  if (!BackButton) {
    return {
      isVisible: false,
      show: () => {
        console.info('useBackButton');
      },
      hide: () => {
        console.info('useBackButton');
      },
      onClick: () => {
        console.info('useBackButton');
      },
      offClick: () => {
        console.info('useBackButton');
      },
      onClickCustom: () => {
        console.info('useBackButton');
      },
      offClickCustom: () => {
        console.info('useBackButton');
      },
    };
  }

  return {
    ...BackButton,
    onClickCustom(callback: () => void) {
      BackButton.onClick(callback);
      if (!BackButton.isVisible) {
        BackButton.show();
      }
    },
    offClickCustom(callback: () => void) {
      BackButton.offClick(callback);
    },
  };
};

export default useBackButton;
