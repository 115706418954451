import { FC, memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import {
  categoriesState,
  selectedCategoryData,
  selectorCategory,
  setCategory,
  setSubCategory,
} from '../../redux/slices/categoriesSlice';
import useLoader from 'src/hooks/useLoader';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate, useParams } from 'react-router-dom';
import { getProductsList } from '../../redux/api/getProductsList';
import { ProductsListTranslator } from '../../redux/translators/productsListTranslator';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import { getMetadata } from '../../redux/api/getMetadata';
import { Products } from 'src/components/Products/Products';
import { searchState } from 'src/redux/slices/searchSlice';

export const SubcategoryPage: FC = memo(function SubcategoryPage() {
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const { hideLoader } = useLoader();
  const dispatch = useAppDispatch();
  const { categoryId, subCategoryId } = useParams();
  const categories = useAppSelector(selectorCategory);
  const { selectedCategory, selectedSubCategory } = useAppSelector(selectedCategoryData);
  const { networkStatus } = useAppSelector(categoriesState);
  const { isOpen: searchIsOpen} = useAppSelector(searchState);

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getMetadata());
    }
  }, [dispatch, networkStatus]);

  useEffect(() => {
    const category = categories.find(el => el.category.uuid === categoryId);
    if (!category) return;
    const subCategory = category?.subCategories.find(el => el.subCategory.uuid === subCategoryId);
    dispatch(setCategory(category.category));
    dispatch(setSubCategory(subCategory.subCategory));
  }, [categories, categoryId, subCategoryId, dispatch]);

  useEffect(() => {
    const getCategoryProductsFn = async () => {
      const params = ProductsListTranslator.toRequestProductsBySubCategory(
        categoryId,
        subCategoryId,
      );
      await dispatch(getProductsList(params));
      hideLoader();
    };
    getCategoryProductsFn();
  }, [dispatch, hideLoader, categoryId, subCategoryId]);

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate(-1);
    }
    if(searchIsOpen) {
      BackButton?.offClickCustom(goBack);
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, searchIsOpen, navigate]);

  useEffect(() => {
    return () => {
      dispatch(setSubCategory(null))
    }
  // eslint-disable-next-line
  }, [])

  return <Products title={selectedSubCategory?.title} subtitle={selectedCategory?.title} />;
});
