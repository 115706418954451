import { PromiseClient } from '@connectrpc/connect/dist/cjs/promise-client';
import { PortClientService } from '@teleport/schemas-protobuf';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import { createPromiseClient } from '@connectrpc/connect';
import { headersAuthInterceptor } from './interceptors/headersAuthInterceptor';
import { statusCodeInterceptor } from './interceptors/statusCodeInterceptor';
import { isValidUrl } from 'src/utils/url/isValidUrl';

export const portApi = (baseUrl: string): PromiseClient<typeof PortClientService> => {
  if (!isValidUrl(baseUrl)) {
    baseUrl = '';
  }

  const transport = createGrpcWebTransport({
    baseUrl,
    useBinaryFormat: true,
    binaryOptions: {
      readUnknownFields: true,
    },
    interceptors: [headersAuthInterceptor, statusCodeInterceptor],
  });

  return createPromiseClient(PortClientService, transport);
};
