import { ITranslation, Language } from './i18n.interface';

const languageMap: {
  [key in Language]: () => Promise<{ default: ITranslation }>;
} = {
  [Language.en]: () => import('./translations/en.json' /* webpackChunkName: "i18n-en" */),
  [Language.ru]: () => import('./translations/ru.json' /* webpackChunkName: "i18n-ru" */),
};

export async function getTranslation(language: Language): Promise<ITranslation> {
  try {
    const translation = languageMap[language];

    const result = await translation();

    return result.default;
  } catch (error) {
    console.error('Could not load translation', error);

    return undefined;
  }
}
