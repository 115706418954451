import { ITheme } from './theme.interface';

// todo dark theme variables
export const themeDark: ITheme = {
  background: '#f0f0f0',
  colorBlack: '#1c1916',
  colorBlue: '#0500FF',
  mainColor: '#0500ff',
  colorWhite: '#ffffff',
  colorRed: '#E42222',
  colorGrey: '#dedede',
  colorDarkGrey: '#a0a0a0',
  colorDarkGreen: '#226620',

  // rgb
  colorRgbMain: '5, 0, 255',
  colorRgbWhite: '255, 255, 255',
  colorRgbBlack: '28, 25, 22',

  gradientBlue:
    'linear-gradient(110deg,rgba(9, 5, 171, 1) 0%,rgba(7, 3, 213, 1) 50%,rgba(78, 76, 181, 1) 75%)',

  transitionDuration: '.3s',
  appMaxWidth: 750,
  appFooterHeight: 106,
  defaultFont: 'Montserrat, sans-serif',
};
