import { SkeletonText } from '../../../../components/UI/SkeletonText/SkeletonText';
import { useStyle } from '../../../../utils/theme/useStyle';
import { productMainInfoRules } from './productMainInfo.style';

const ProductMainInfoSkeleton = () => {
  const { css } = useStyle(productMainInfoRules);
  return (
    <div>
      <div className={`${css.header} ${css.skeletonHeader}`}>
        <SkeletonText width={'40%'} height={22} />
        <SkeletonText width={100} height={22} />
      </div>
      <SkeletonText width={'30%'} height={15} />
    </div>
  );
};

export default ProductMainInfoSkeleton;
