export async function checkIfImageExists(url: string) {
  const promise = new Promise<boolean>(resolve => {
    const img = new Image();
    img.src = url;

    img.onload = () => {
      resolve(true);
    };

    img.onerror = () => {
      resolve(false);
    };
  });

  const result = await promise;

  return result;
}
