import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IButtonRuleKeys {
  button: {};
  text: {};
  icon: {};
}

interface IButtonRuleProps {
  active?: boolean;
  width: string | number;
  height: string | number;
  padding: string | number;
  background: string;
  color: string;
  disabled?: boolean;
  iconAtTheEnd?: boolean;
  borderRadius?: string | number;
  hasGradient;
}

export type IButtonRules = IRuleFn<IButtonRuleKeys, IButtonRuleProps>;

const buttonRuleFn: IButtonRules = props => {
  const { colorWhite, mainColor } = props.theme;
  const { width, height, background, padding, color, active, disabled, hasGradient, borderRadius, iconAtTheEnd } =
    props;
  // Если задано значение его нельзя будет переопределить с помощью extand
  return {
    button: {
      position: 'relative',
      padding: padding ?? '22px',
      width,
      height,
      fontSize: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: active ? mainColor : background || mainColor,
      color: color ?? colorWhite,
      border: 'none',
      borderRadius: borderRadius ?? '12px',
      borderColor: 'transparent',
      outline: 'none',
      cursor: 'pointer',
      opacity: disabled ? '0.5' : '1',
      pointerEvents: disabled ? 'none' : 'auto',

      '::before': {
        content: '""',
        borderRadius: borderRadius ?? '12px',
        display: active || !hasGradient ? 'none' : 'block',
        position: 'absolute',
        inset: 0,
        background:
          'linear-gradient(90deg, rgba(255,255,255, 0) 25%, rgba(255,255,255,0.2246393557422969) 50%, rgba(255,255,255,0.2046393557422969) 100%);',
      },

      ':active': {
        opacity: 0.85,
      },
    },
    text: {
      fontSize: 16,
      lineHeight: '18px',
      fontWeight: 700,
      letterSpacing: '-0.4px',
    },
    icon: {
      order: iconAtTheEnd ? 0 : -1,
    },
  };
};

export const buttonRules = createCachedStyle(buttonRuleFn);
