import { Category, Filter, RootCategory, SubCategory } from '@teleport/schemas-protobuf';

export interface IFilterComplex {
  query?: string;
  category?: RootCategory;
  subCategory?: SubCategory;
  selectionUuid?: string;
}

export const FilterTranslator = {
  toCategoryFilter(category: RootCategory) {
    const categoryInstance = new Category({
      type: {
        case: 'rootCategory',
        value: { title: category.title, uuid: category.uuid },
      },
    });
    return new Filter({
      filterDefaultState: { case: 'categoryFilter', value: { category: categoryInstance } },
    })
  },

  toSubCategoryFilter(subCategory: SubCategory) {
    const subCategoryInstance = new Category({
      type: {
        case: 'subCategory',
        value: { title: subCategory.title, uuid: subCategory.uuid },
      },
    });
    return new Filter({
      filterDefaultState: { case: 'categoryFilter', value: { category: subCategoryInstance } },
    })
  },

  toSearchFilter(query: string) {
    return new Filter({
      filterDefaultState: { case: 'searchFilter', value: { query } },
    });
  },

  toSelectionFilter(selectionUuid: string) {
    return new Filter({
      filterDefaultState: { value: { selectionUuid }, case: 'selectionFilter' },
    });
  },

  toFiltersComplex(params: IFilterComplex) {
    const {
      query,
      category: selectedCategory,
      subCategory: selectedSubCategory,
      selectionUuid,
    } = params;
    const filters: Filter[] = [];

    if (selectedCategory) {
      const category = new Category({
        type: {
          case: 'rootCategory',
          value: { title: selectedCategory.title, uuid: selectedCategory.uuid },
        },
      });
      filters.push(new Filter({
        filterDefaultState: { case: 'categoryFilter', value: { category } },
      }));
    }

    if (selectedSubCategory) {
      const subCategory = new Category({
        type: {
          case: 'subCategory',
          value: { title: selectedSubCategory.title, uuid: selectedSubCategory.uuid },
        },
      });
      filters.push(new Filter({
        filterDefaultState: { case: 'categoryFilter', value: { category: subCategory } },
      }));
    }

    if(query) {
      const searchFilter = new Filter({
        filterDefaultState: { case: 'searchFilter', value: { query } },
      });
      filters.push(searchFilter);
    }

    if (selectionUuid) {
      const selectionFilter = new Filter({
        filterDefaultState: { value: { selectionUuid }, case: 'selectionFilter' },
      });
      filters.push(selectionFilter);
    }

    return filters;
  },
};
