import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { ModalResultRules } from './ModalResult.style';
import Modal, { ModalNames } from '../Modal/Modal';
import ErrorOrSuccessInfo from '../ErrorOrSuccessInfo/ErrorOrSuccessInfo';
import { Button } from '../UI/Button/Button';
import {useTranslation} from '../../utils/i18n/hooks/useTranslation';

interface IProps {
  active: boolean;
  success: boolean;
  title: string;
  text: string;
  onClose: () => void;
}

export const ModalResult: FC<IProps> = memo(function ModalResult(props) {
  const { success, text, title, active, onClose } = props;
  const { css } = useStyle(ModalResultRules);
  const {t} = useTranslation();

  return (
    <Modal
      active={active}
      name={ModalNames.RESULT}
      onClose={onClose}
      propsStyles={{ paddingBottom: 32 }}
    >
      <div className={css.content}>
        <ErrorOrSuccessInfo success={success} title={title} text={text} />
      </div>
      <footer className={css.footer}>
        <Button
          onClick={onClose}
          text={t('modalResult.okay')}
          propsStyles={{
            width: '100%',
          }}
        />
      </footer>
    </Modal>
  );
});
