import { FC, memo, useState } from 'react';

interface IProps {
  src: string;
  alt?: string;
  addClass?: string;
}

export const ImageComponent: FC<IProps> = memo(function Price(props) {
  const { src, alt, addClass } = props;
  const [imgSrc, setImgSrc] = useState(src);

  const handleError = () => {
    setImgSrc('/images/stub.svg');
  };

  return <img loading={'lazy'} className={`${addClass} test`} src={imgSrc} alt={alt} onError={handleError} />;
});
