import { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { Layout } from 'src/components/Layout/Layout';
import { useStyle } from 'src/utils/theme/useStyle';
import { cartRules } from './сart.style';
import Container from 'src/components/Container/Container';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import EmptyCart from 'src/components/EmptyCart/EmptyCart';
import { CartItem } from 'src/components/CartItem/CartItem';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { cartState, resetCartNetworkStatus, TypeOfDiscount } from '../../redux/slices/cartSlice';
import { useTheme } from 'src/utils/theme/useTheme';
import { useModalState } from 'src/hooks/useModalState';
import ModalPromocode from 'src/components/ModalPromocode/ModalPromocode';
import useLoader from 'src/hooks/useLoader';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import useCurrency from 'src/hooks/useCurrency';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate } from 'react-router-dom';
import { telegramStarsString } from 'src/utils/constants';
import { RoutePath } from 'src/providers/AppRouter/routeConfig';
import { Price } from '../../components/Price/Price';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { togglesState } from 'src/redux/slices/configSlice';
import { getAllModifiers, modifiersState } from 'src/redux/slices/modifiersSlice';
import CartItemSkeleton from '../../components/CartItem/CartItemSkeleton';
import CartInfoSkeleton from './CartInfoSkeleton';
import { cartGet } from 'src/redux/api/cart/cartGet';
import { modalErrorState, openErrorModal } from '../../redux/slices/modalErrorSlice';

export const Cart: FC = memo(function Cart() {
  const { css } = useStyle(cartRules);
  const { theme } = useTheme();
  const currency = useCurrency();
  const isTelegramCurrency = currency === telegramStarsString
  const { products, minPrice, total, promoCode, networkStatus, networkStatusChangeQuantityProduct, networkStatusRemoveProduct, errorChangeQuantity } = useAppSelector(cartState);
  const { modifiers } = useAppSelector(modifiersState);
  const { modalErrorIsOpen } = useAppSelector(modalErrorState);

  const { hideLoader } = useLoader();
  const navigate = useNavigate();
  const BackButton = useBackButton();
  const { promoCodeEnabled } = useAppSelector(togglesState);

  const [renderPromoModal, activePromoModal, openModalPromo, closeModalPromo] = useModalState();

  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const clickOrderButton = useCallback(() => {
    navigate(RoutePath.makeOrder);
  }, [navigate]);

  useEffect(() => {
    if(modalErrorIsOpen) {
      dispatch(resetCartNetworkStatus('networkStatusChangeQuantityProduct'))
      dispatch(resetCartNetworkStatus('networkStatusRemoveProduct'))
    }
  }, [dispatch, modalErrorIsOpen]);

  useEffect(() => {
    if (networkStatusChangeQuantityProduct === NetworkStatus.Failed ) {
      if (errorChangeQuantity.includes('CART_ERROR_OUTDATED')) {
        dispatch(cartGet());
      } else {
        dispatch(openErrorModal({errorTitle: t('cart.failedToChangeTheProductQuantity')}))
      }
    }
  }, [t, dispatch, errorChangeQuantity, networkStatusChangeQuantityProduct, networkStatusRemoveProduct])

  useEffect(() => {
    if (networkStatusRemoveProduct === NetworkStatus.Failed ) {
      dispatch(openErrorModal({errorTitle: t('cart.failedToRemoveTheProduct')}))
    }
  }, [t, dispatch, networkStatusRemoveProduct])

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate(-1);
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  useEffect(() => {
    dispatch(cartGet())
    dispatch(getAllModifiers())
  }, [dispatch]);

  useEffect(() => {
    hideLoader()
  }, [hideLoader]);


  const content = useMemo(() => {
    if (networkStatus === NetworkStatus.Loading) {
      return (
        <div className={css.cart_wrapper}>
          <div className={css.cards_wrapper}>
            <CartItemSkeleton />
            <CartItemSkeleton />
            <CartItemSkeleton />
            <CartItemSkeleton />
            <CartItemSkeleton />
          </div>
          <CartInfoSkeleton />
        </div>
      )
    } else if (products.length > 0) {
      return (
        <div className={css.cart_wrapper}>
          <div className={css.cards_wrapper}>
            {products.map(el => {
              return <CartItem extend={css.card} modifiers={modifiers} key={el.id} data={el} />;
            })}
          </div>

          <div className={css.cart_info}>
            <Container>
              { promoCodeEnabled && (
                <div className={css.summary_row}>
                  <div onClick={() => openModalPromo()} className={css.summary_row_inner}>
                    {promoCode?.codename ? (
                      <div>
                        <p className={css.promocodeName}>{promoCode.codename}</p>
                        <p className={css.promocodeVal}>{`${promoCode.impact.value} ${promoCode.impact.case === TypeOfDiscount.PriceImpact? '%' : isTelegramCurrency? <img style={{width: 20}} src={`/images/currencies/${currency}.svg`} alt='' /> : currency}`}</p>
                      </div>
                    ) : (
                      <p>{t('cart.promoCode')}</p>
                    )}

                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.909 10.7143L15.9795 10.7143L11.1504 15.5434C10.8096 15.8842 10.8096 16.4524 11.1504 16.7932C11.4913 17.1341 12.0594 17.1341 12.4003 16.7932L18.7443 10.4492C19.0852 10.1083 19.0852 9.54021 18.7443 9.19934L12.4003 2.85531C12.2299 2.68487 12.0026 2.59018 11.7754 2.59018C11.5481 2.59018 11.3209 2.68487 11.1504 2.8553C10.8096 3.19618 10.8096 3.7643 11.1504 4.10518L15.9795 8.93421L1.89006 8.93421C1.39768 8.93421 1 9.3319 1 9.82427C1.01894 10.3166 1.41662 10.7143 1.909 10.7143Z"
                        fill="#1C1916"
                      />
                    </svg>
                  </div>
                </div>
              )}
              <div className={css.summary_row}>
                {total?.productTotal?.oldAmount && (
                  <div className={css.summary_row_inner}>
                    <p>{t('cart.orderTotal')}</p>
                    <Price amount={total?.productTotal?.oldAmount} hasSymbol={false} fontWeight={700} fontSize={14} lineHeight={'18px'} />
                  </div>
                )}
                {total?.productTotal?.discountEnabled && (total?.productTotal.oldAmount - total?.productTotal.amount) > 0 && (
                  <div className={css.summary_row_inner}>
                    <p>{t('cart.discount')}</p>
                    <Price
                      amount={total?.productTotal.oldAmount - total?.productTotal.amount}
                      hasSymbol={true}
                      fontWeight={700}
                      fontSize={14}
                      lineHeight={'18px'}
                      isPositive={false}
                      color={theme.mainColor}
                      extend={css.total_disc}
                    />
                  </div>
                )}

                {total?.promocodeDiscount && (
                  <div className={css.summary_row_inner}>
                    <p>{t('cart.discountWithPromoCode')}</p>
                    <p className={`${css.total_disc} ${css.total_amount}`}>
                      - {Number(total.promocodeDiscount)} {isTelegramCurrency? <img style={{width: 20}} src={`/images/currencies/${currency}.svg`} alt='' /> : currency }{' '}
                    </p>
                  </div>
                )}
              </div>
              {total?.productTotal?.amount && (
                <div className={css.to_pay}>
                  <p>{t('cart.goToPayment')}</p>
                  <Price amount={total.productTotal.amount} hasSymbol={false} fontWeight={700} fontSize={20} lineHeight={'22px'} />
                </div>
              )}
              <Button
                disabled={total?.productTotal?.amount < minPrice}
                text=""
                propsStyles={{width: '100%', height: 62}}
                onClick={clickOrderButton}
              >
                <div>
                  <p style={{fontSize: '16px', lineHeight: '18px', fontWeight: '700'}}>
                    {t('cart.checkout')}
                  </p>
                  {total?.productTotal?.amount < minPrice && (
                    <p style={{ fontSize: '12px', lineHeight: '14px', fontWeight: '400' }}>
                      {t('cart.minimumOrderFrom', minPrice)} {isTelegramCurrency? <img style={{width: 20}} src={`/images/currencies/${currency}.svg`} alt='' /> : currency }{' '}
                    </p>
                  )}
                </div>
              </Button>
            </Container>
          </div>
        </div>
      )
    } else {
      return (
        <div className={css.cart_wrapper}>
          <EmptyCart/>
        </div>
      )
    }
  }, [
    css,
    currency,
    isTelegramCurrency,
    minPrice,
    networkStatus,
    openModalPromo,
    clickOrderButton,
    products,
    promoCode,
    t,
    theme,
    total,
    modifiers,
    promoCodeEnabled
  ])

  let footer = undefined;

  if (products.length === 0 && networkStatus === NetworkStatus.Done) {
    footer = (
      <Footer
        propsStyles={{
          backgroundColor: products.length === 0 ? theme.background : theme.colorWhite,
        }}
      >
        <Button href={'/catalog'} text={t('cart.catalog')} propsStyles={{width: '100%', height: 62}}/>
      </Footer>
    )
  }

  return (
    <Layout
      mainFlexColumn={true}
      footer={footer}
    >
      {content}
      {promoCodeEnabled && renderPromoModal && (
        <ModalPromocode
          value={promoCode?.codename ? promoCode.codename : ''}
          onClose={closeModalPromo}
          active={activePromoModal}
        />
      )}
    </Layout>
  );
});
