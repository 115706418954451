import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IProductModificatorsKeys {
  modificators: {};
  modificator: {};
}

export type IProductModificatorss = IRuleFn<IProductModificatorsKeys>;

const productModificatorsRuleFn: IProductModificatorss = () => ({
  modificators: {},
  modificator: {
    ':first-child': {
      padding: '16px 0',
    },
    padding: '12px 0',
  },
});

export const productModificatorsRules = createCachedStyle(productModificatorsRuleFn);
