import { memo, useCallback, useState } from 'react';
import { ReactComponent as SvgNoImage } from 'src/assets/svg/no-image.svg';
import { useStyle } from 'src/utils/theme/useStyle';
import { Link } from 'react-router-dom';
import { productCardRules } from './productCard.style';
import { ClientCatalogProductShort } from '@teleport/schemas-protobuf';
import { ImageComponent } from '../ImageComponent/ImageComponent';
import useCurrency from 'src/hooks/useCurrency';
import { FavoriteButton } from '../UI/FavoriteButton/FavoriteButton';
import {
  favouritesState,
  productToFavoriteBind,
  productToFavoriteUnbind,
} from 'src/redux/slices/favouritesSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide, } from 'swiper/react';
import { telegramStarsString } from 'src/utils/constants';

interface IProps {
  data: ClientCatalogProductShort;
  withSlider?: boolean;
  size: ProductCardSize;
  extend?: string;
}

export interface IStyles {
  fill?: string;
}

export enum ProductCardSize {
  SMALL = 'small',
  ADAPTIVE = 'adaptive',
}

const ProductCard = function ProductCard(props: IProps) {
  const { data, withSlider, size, extend = false } = props;
  const { uuid, title, previewImageUrls, price } = data;
  const sliderExist = withSlider && previewImageUrls && previewImageUrls.length > 0;
  const currency = useCurrency();
  const { css } = useStyle(productCardRules, { sliderExist, size, telegramCurrency: currency === telegramStarsString});
  const [paginationEl, setPaginationEl] = useState<HTMLDivElement>(null)
  const customPaginationRef = useCallback(node => {
    if(node !== null) {
      setPaginationEl(node)
    }
  }, []);

  const dispatch = useAppDispatch();
  const { productUuids } = useAppSelector(favouritesState);

  const addOrDellFavourites = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    if (productUuids.includes(data.uuid)) {
      dispatch(
        productToFavoriteUnbind({
          productUuids: [data.uuid],
          currentUuid: data.uuid,
          isFavourite: false,
        }),
      );
    } else {
      dispatch(
        productToFavoriteBind({
          productUuids: [...productUuids, data.uuid],
          currentUuid: data.uuid,
          isFavourite: true,
        }),
      );
    }
  };

  const isTelegramCurrency = currency === telegramStarsString

  return (
    <Link to={`/product/${uuid}`} className={[css.card, extend].join(' ')}>
      {sliderExist ? (
        <>
          <div className={css.sliderWrapper}>
            <Swiper
              modules={[Pagination]}
              spaceBetween={8}
              slidesPerView={'auto'}
              pagination={{
                el: paginationEl,
                bulletClass: previewImageUrls.length > 1 ? css.bullet : css.bulletHidden,
                bulletActiveClass: css.bulletActive,
              }}
              className={css.slider}
            >
              {previewImageUrls.map(slideImg => (
                <SwiperSlide className={css.slide} key={slideImg}>
                  <div className={css.productImage}>
                    <ImageComponent src={slideImg} alt="product" addClass={css.img} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <FavoriteButton
              extend={css.buttonAddFavorite}
              onClick={addOrDellFavourites}
              isFavorite={productUuids.includes(data.uuid)}
            />
            {Boolean(price.discountInPercent) && (
              <div className={css.discount}>-{price.discountInPercent}%</div>
            )}
          </div>
          <div ref={customPaginationRef} className={css.pagination} />
        </>
      ) : (
        <div className={css.productImage}>
          {previewImageUrls?.length ? (
            <ImageComponent src={previewImageUrls[0]} alt="product" addClass={css.img} />
          ) : (
            <div className={css.noimg}>
              <SvgNoImage />
            </div>
          )}
          <FavoriteButton
            extend={css.buttonAddFavorite}
            onClick={addOrDellFavourites}
            isFavorite={productUuids.includes(data.uuid)}
          />
          {Boolean(price.discountInPercent) && (
            <div className={css.discount}>-{price.discountInPercent}%</div>
          )}
        </div>
      )}

      <p className={css.name}>{title}</p>
      <div className={css.priceWrapper}>
        {price.discountInPercent ? (
          <>
            <span className={[css.price, css.discountPrice].join(' ')}>
              {Number(price.amount)}
              {isTelegramCurrency? <img className={css.imgTelegram} src={`/images/currencies/${currency}.svg`} alt='' /> : currency }
            </span>
            <span className={[css.price, css.oldPrice].join(' ')}>
              {Number(price.oldAmount)}
              {isTelegramCurrency? <img style={{width: 16}} className={css.imgTelegram} src={`/images/currencies/${currency}.svg`} alt='' /> : currency }
            </span>
          </>
        ) : (
          <span className={css.price}>
            {Number(price.amount)}
            {isTelegramCurrency? <img className={css.imgTelegram} src={`/images/currencies/${currency}.svg`} alt='' /> : currency }
          </span>
        )}
      </div>
    </Link>
  );
};

export default memo(ProductCard);
