import { memo } from 'react';
import Text from '../../../../components/UI/Text/Text';
import { useStyle } from '../../../../utils/theme/useStyle';
import { productDescriptionRules } from './ProductDescription.style';
import { SkeletonText } from '../../../../components/UI/SkeletonText/SkeletonText';
import { useTranslation } from '../../../../utils/i18n/hooks/useTranslation';

export const ProductDescriptionSkeleton = memo(function ProductDescriptionSkeleton() {
  const { css } = useStyle(productDescriptionRules, {open: false});
  const { t } = useTranslation();

  return (
    <div className={css.wrapper}>
      <Text text={t('productDescription.description')} mod="title" extend={css.title}/>
      <div className={css.skeletonDescription}>
        <SkeletonText width={'100%'} height={20} />
        <SkeletonText width={'100%'} height={20} />
        <SkeletonText width={'100%'} height={20} />
        <SkeletonText width={'100%'} height={20} />
      </div>
    </div>
  );
});
