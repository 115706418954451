import { memo, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import useLoader from 'src/hooks/useLoader';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate } from 'react-router-dom';
import { resetSearch, searchState } from '../../redux/slices/searchSlice';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import { productsState } from 'src/redux/slices/productsListSlice';
import { getProductsList } from 'src/redux/api/getProductsList';
import { Products } from 'src/components/Products/Products';

interface IProps {
  className?: string;
}

const Suggestions = function Suggestions(props: IProps) {
  const {} = props;
  const { isOpen: searchIsOpen, value } = useAppSelector(searchState);
  const dispatch = useAppDispatch();
  const { hideLoader } = useLoader();
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const { networkStatus } = useAppSelector(productsState);

  useEffect(() => {
    if(networkStatus === NetworkStatus.None && !value) {
      dispatch(getProductsList({ 
        filters: [], 
        sorting: 0,
      }))
    }
  }, [networkStatus, value, dispatch])

  useEffect(() => {hideLoader()}, [hideLoader]);

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate(-1);
    }

    if(searchIsOpen) {
      BackButton?.offClickCustom(goBack);
    }

    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, searchIsOpen, navigate]);

  useEffect(() => {
    return () => {
      dispatch(resetSearch());
    };
  }, [dispatch]);


  return <Products />;
};

export default memo(Suggestions);
