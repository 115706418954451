import { FC, memo, useEffect, useMemo } from 'react';
import { Button } from 'src/components/UI/Button/Button';
import { Stories } from 'src/blocks/Stories/Stories';
import Wrapper from 'src/components/Wrapper/Wrapper';
import InfoBlock from 'src/components/InfoBlock/InfoBlock';
import Advertising from 'src/components/Advertising/Advertising';
import Watermark from 'src/components/Watermark/Watermark';
import Footer from 'src/components/Footer/Footer';
import { Layout } from 'src/components/Layout/Layout';
import Header from 'src/components/Header/Header';
import Container from 'src/components/Container/Container';
import { useStyle } from 'src/utils/theme/useStyle';
import { mainPageRules } from './main.style';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getStatus, mainState } from '../../redux/slices/mainSlice';
import { NetworkStatus } from '../../utils/connect/connectConstant';
import {
  SelectionBlock,
  SliderBlock,
  StoriesBlock,
  TextBlock,
} from '@teleport/schemas-protobuf';
import useLoader from 'src/hooks/useLoader';
import StoreNotWorking from 'src/components/StoreNotWorking/StoreNotWorking';
import Selection from 'src/components/Selection/Selection';
import { selectionsState } from '../../redux/slices/selectionsSlice';
import useBackButton from 'src/hooks/useBackButton';
import {useTranslation} from '../../utils/i18n/hooks/useTranslation';
import { StoriesSkeleton } from '../../blocks/Stories/StoriesSkeleton';
import { InfoBlockSkeleton } from '../../components/InfoBlock/InfoBlockSkeleton';
import { AdvertisingSkeleton } from '../../components/Advertising/AdvertisingSkeleton';
import SelectionSkeleton from '../../components/Selection/SelectionSkeleton';

function isStoriesBlock(_value: any, blockCase: string): _value is StoriesBlock {
  return blockCase === 'storiesBlock';
}

function isTextBlock(_value: any, blockCase: string): _value is TextBlock {
  return blockCase === 'textBlock';
}

function isSliderBlock(_value: any, blockCase: string): _value is SliderBlock {
  return blockCase === 'sliderBlock';
}

function isSelectionBlock(_value: any, blockCase: string): _value is SelectionBlock {
  return blockCase === 'selectionBlock';
}

export const MainPage: FC = memo(function MainPage() {
  const { css } = useStyle(mainPageRules);
  const { t } = useTranslation();
  const { blocks, hasMaintenance, maintenance, networkStatus } = useAppSelector(mainState);
  const { mainPageSelections } = useAppSelector(selectionsState);
  const dispatch = useAppDispatch();
  const { hideLoader } = useLoader();
  const BackButton = useBackButton();

  useEffect(() => {
    BackButton.hide();
  }, [BackButton]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getStatus());
    }
  }, [dispatch, networkStatus]);

  useEffect(() => {
    hideLoader();
  }, [hideLoader]);

  // container
  // titleStyle
  // textStyle
  // хранить шаг в локалсторадже

  const content = useMemo(() => {
    if (networkStatus === NetworkStatus.Loading) {
      return (
        <>
          <StoriesSkeleton />
          <Wrapper>
            <Container>
              <InfoBlockSkeleton />
              <InfoBlockSkeleton />
            </Container>
          </Wrapper>
          <Wrapper>
            <AdvertisingSkeleton />
          </Wrapper>
          <Wrapper>
            <SelectionSkeleton />
          </Wrapper>
        </>
      )
    } else {
      return (
        <>
          {blocks.map((block, index) => {
            const {
              blockData: { value, case: blockCase },
            } = block;
            if (isStoriesBlock(value, blockCase)) {
              return (
                <Stories
                  key={index}
                  slides={value.items}
                  propsStyles={{ paddingBottom: 20, paddingTop: 8 }}
                />
              );
            }
            if (isTextBlock(value, blockCase)) {
              return (
                <Wrapper key={index}>
                  <Container>
                    <InfoBlock
                      title={value.title}
                      text={value.text}
                      showButton={value.button.enabled}
                      buttonText={value.button.text}
                      buttonUrl={value.button.clickUrl}
                      accentColor={value.accentColor}
                    />
                  </Container>
                </Wrapper>
              );
            }

            if (isSliderBlock(value, blockCase)) {
              return (
                <Wrapper key={index}>
                  <Advertising slides={value.items} />
                </Wrapper>
              );
            }

            // moved the rendering of the selections down
            if (isSelectionBlock(value, blockCase)) {
              const selection = mainPageSelections.find(selection => selection.uuid === value.uuid);
              if (!selection) return null;
              return (
                <Wrapper key={selection.uuid}>
                  <Selection selectionData={selection} location="mainPage" extend={css.collection} />
                </Wrapper>
              );
            }

            console.warn('unknown block case');

            return null;
          })}
          <Wrapper>
            <Watermark />
          </Wrapper>
        </>
      )
    }
  }, [
    css,
    blocks,
    mainPageSelections,
    networkStatus
  ])

  if (hasMaintenance) {
    return (
      <StoreNotWorking reasonText={maintenance.reasonText} supportLink={maintenance.supportLink} />
    );
  }

  return (
    <Layout
      header={<Header />}
      footer={
        <Footer>
          <Button href="/catalog" text={t('mainPage.catalog')} propsStyles={{ width: '100%', height: 62 }} />
        </Footer>
      }
    >
      {content}
    </Layout>
  );
});
