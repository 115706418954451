import { memo, useRef } from 'react';
import Modal, { ModalNames } from 'src/components/Modal/Modal';
import { useStyle } from 'src/utils/theme/useStyle';
import Text from 'src/components/UI/Text/Text';
import { Info } from 'src/components/UI/Info/info';
import { OptionCard } from 'src/components/Modificators/ModificatorList/components/OptionCard/OptionCard';
import { IModificatorListValue } from 'src/types/modificators';
import { modalModificatorListRules } from './ModalModificatorList.style';
import { IModifierVariant } from 'src/redux/slices/productDetailSlice';
import { ICompoundVariant } from 'src/blocks/Product/components/ProductModificators/ProductModificators';

interface IProps {
  active: boolean;
  modificatorName: string;
  modificatorLink?: string;
  onChange: (value: IModificatorListValue) => void;
  onClose: () => void;
  variants: ICompoundVariant[]
  chosenVariants: IModifierVariant[]
}

const ModalModificatorList = function ModalModificatorList(props: IProps) {
  const { active, modificatorName, onChange, onClose, variants, chosenVariants, modificatorLink } = props;
  const modalHeaderRef = useRef<HTMLDivElement>(null);

  const { css } = useStyle(modalModificatorListRules, {
    headerHeight: modalHeaderRef.current?.clientHeight,
  });

  return (
    <Modal active={active} name={ModalNames.MODIFICATOR} onClose={onClose}>
      <div ref={modalHeaderRef} className={css.header}>
        <Text text={modificatorName} mod="title" textTransform="uppercase" />
        {modificatorLink && <Info href={modificatorLink} />}
      </div>
      <div className={css.modalInner}>
        {
          variants.map(variant => {
            return (
              <OptionCard
                key={variant.uuid}
                name="equipment"
                value={variant.uuid}
                selectedValue={chosenVariants.find(el => el.uuid === variant.uuid)?.uuid}
                text={variant.title}
                type="checkbox"
                onChange={onChange}
                price={Number(variant.priceImpact)}
              />
            )
          })
        }
      </div>
    </Modal>
  );
};

export default memo(ModalModificatorList);
