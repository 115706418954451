import { ThemeName } from './theme.interface';
import { themeDark } from './themeDark';
import { themeLight } from './themeLight';

export function getTheme(themeName: ThemeName) {
  switch (themeName) {
    case ThemeName.Dark:
      return themeDark;
    case ThemeName.Light:
    default:
      return themeLight;
  }
}
