import { useCallback } from 'react';

const useLoader = () => {
  let loader: Element | null = null;

  if (typeof document !== 'undefined') {
    loader = document?.querySelector('.loader-wrapper');
  }

  const showLoader = useCallback(() => {
    loader?.classList.remove('is-hidden');
  }, [loader]);

  const hideLoader = useCallback(() => {
    loader?.classList.add('is-hidden');
  }, [loader]);

  return { showLoader, hideLoader };
};

export default useLoader;
