import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModificatorTogglerRuleKeys {
  modificator: {};
  info: {};
  togglerWrapper: {};
  price: {};
}

interface IModificatorTogglerRuleProps {
  isSelected: boolean;
}

export type IModificatorTogglerRules = IRuleFn<
  IModificatorTogglerRuleKeys,
  IModificatorTogglerRuleProps
>;

const modificatorTogglerRuleFn: IModificatorTogglerRules = props => {
  const { colorBlack, colorDarkGrey } = props.theme;
  const { isSelected } = props;

  return {
    modificator: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      columnGap: '9px',
    },

    info: {
      display: 'flex',
      alignItems: 'center',
      columnGap: '9px',
    },

    togglerWrapper: {
      display: 'flex',
      alignItems: 'center',
      columnGap: '8px',
    },

    price: {
      color: isSelected ? colorBlack : colorDarkGrey,
    },
  };
};

export const modificatorTogglerRules = createCachedStyle(modificatorTogglerRuleFn);
