import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IBannerRuleKeys {
  banner: {};
  text: {};
}

interface IBannerRuleProps {
  background: string;
}

export type IBannerRules = IRuleFn<IBannerRuleKeys, IBannerRuleProps>;

const bannerRuleFn: IBannerRules = props => {
  const { background } = props;
  const { colorWhite } = props.theme;

  return {
    banner: {
      display: 'block',
      border: 'none',
      backgroundColor: 'none',
      width: 344,
      height: 344,
      padding: '11px 12px',
      borderRadius: 16,
      backgroundImage: `url(${background})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    text: {
      display: 'block',
      textAlign: 'end',
      fontWeight: 700,
      whiteSpace: 'pre-wrap',
      fontSize: 20,
      lineHeight: '26px',
      color: colorWhite,
    },
  };
};

export const bannerRules = createCachedStyle(bannerRuleFn);
