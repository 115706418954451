import { ReactNode, memo } from 'react';
import { useStyle } from '../../utils/theme/useStyle';
import { containerRules } from './container.style';

interface IProps {
  extend?: string;
  children: ReactNode;
  paddingTop?: number;
  paddingBottom?: number;
}

const Container = function Container(props: IProps) {
  const { extend, children, paddingBottom, paddingTop } = props;
  const { css } = useStyle(containerRules, {
    paddingTop,
    paddingBottom,
  });

  return <div className={[css.root, extend].join(' ')}>{children}</div>;
};

export default memo(Container);
