import { SkeletonImage } from '../../../../components/UI/SkeletonImage/SkeletonImage';
import { memo } from 'react';
import { useStyle } from '../../../../utils/theme/useStyle';
import { ProductSliderRules } from './productSlider.style';

const ProductSliderSkeleton = memo(function ProductSliderSkeleton() {
  const { css, rules } = useStyle(ProductSliderRules, {
    hasPagination: false,
  })

  return (
    <div className={css.skeletonImageWrapper}>
      <SkeletonImage width={'100%'} height={'100%'} borderRadius={'8px'} extend={{root: rules.skeletonImage }} />
    </div>
  );
}) ;

export default ProductSliderSkeleton;
