import { memo } from 'react';
import Text from '../UI/Text/Text';
import { useStyle } from 'src/utils/theme/useStyle';
import { ModalErrorOrSuccessInfoRules } from './ErrorOrSuccessInfo.style';
import { useTheme } from 'src/utils/theme/useTheme';
import { Icon } from '../Icon/Icon';

interface IProps {
  title: string;
  text?: string;
  success: boolean;
}

const ErrorOrSuccessInfo = function ErrorOrSuccessInfo(props: IProps) {
  const { title, text, success } = props;
  const { css } = useStyle(ModalErrorOrSuccessInfoRules);
  const { theme } = useTheme();
  return (
    <>
      <div className={css.iconWrapper}>
        <Icon
          name="guard"
          properties={{
            fill: success ? theme.colorDarkGreen : theme.colorRed,
            className: css.guard,
          }}
        />

        <Icon
          name={success ? 'check' : 'cross-clear'}
          properties={{
            stroke: success ? theme.colorDarkGreen : theme.colorRed,
            fill: success ? theme.colorDarkGreen : theme.colorRed,
            className: css.icon,
          }}
        />
      </div>
      <Text
        extend={css.title}
        text={title}
        mod="text"
        fontWeight={800}
        fontSize={20}
        lineHeight="26px"
      />
      {text && <Text mod="text" text={text} fontSize={14} lineHeight="18px" fontWeight={500} />}
    </>
  );
};

export default memo(ErrorOrSuccessInfo);
