export const SHOP_NAME = process.env['REACT_APP_STORE_NAME'];
export const DEV_TELEGRAM_INIT = process.env['REACT_APP_DEV_TELEGRAM_INIT_DATA'];

export const standardColors = ['#037EE5', '#0500FF', '#A48B66', '#962E2E', '#226620'];

export const standardColorsNames = {
  '#037EE5': 'Голубой',
  '#0500FF': 'Синий',
  '#A48B66': 'Коричневый',
  '#962E2E': 'Красный',
  '#226620': 'Зеленый',
};

export const telegramStarsString = 'stars'

export const promocodeMinLength = 5
export const promocodeMaxLength = 20
