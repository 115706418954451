import { useStyle } from '../../utils/theme/useStyle';
import { productCardRules } from './productCard.style';
import { memo } from 'react';
import { ProductCardSize } from './ProductCard';
import { SkeletonImage } from '../UI/SkeletonImage/SkeletonImage';
import { SkeletonText } from '../UI/SkeletonText/SkeletonText';

const ProductCardSkeleton = function ProductCardSkeleton() {
  const { css, rules } = useStyle(productCardRules, { sliderExist: false, size: ProductCardSize.SMALL, telegramCurrency: false});

  return (
    <div>
      <div className={css.skeletonAdaptiveWrapper}>
        <SkeletonImage
          width={'100%'}
          height={'100%'}
          borderRadius={'8px'}
          extend={{ root: rules.skeletonImage }}
        />
      </div>
      <SkeletonText width={'100%'} height={18} extend={{ root: rules.skeletonTitle }} />
      <SkeletonText width={'50%'} height={18} />
    </div>
  );
};

export default memo(ProductCardSkeleton);
