import { FC, memo } from 'react';
import { useStyle } from '../../../utils/theme/useStyle';
import { ISkeletonImageRuleKeys, skeletonImageRules } from './SkeletonImage.style';

interface IProps {
  width: number | string;
  height: number | string;
  borderRadius: string;
  extend?: Partial<ISkeletonImageRuleKeys>;
}

export const SkeletonImage: FC<IProps> = memo(function SkeletonImage(props) {
  const { width,  height, extend, borderRadius } = props;
  const { css } = useStyle(skeletonImageRules, {
    width,
    height,
    borderRadius,
    extend
  });

  return (
    <div className={css.root}></div>
  );
});
