import { FC, memo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Products } from 'src/components/Products/Products';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import useBackButton from 'src/hooks/useBackButton';
import useLoader from 'src/hooks/useLoader';
import { getProductsList } from 'src/redux/api/getProductsList';
import { getStatus, mainState } from 'src/redux/slices/mainSlice';
import { selectionsState } from 'src/redux/slices/selectionsSlice';
import { ProductsListTranslator } from 'src/redux/translators/productsListTranslator';
import { NetworkStatus } from 'src/utils/connect/connectConstant';

export const SelectionPage: FC = memo(function SelectionPage() {
  const BackButton = useBackButton();
  const { selectionId } = useParams();
  const { mainPageSelections } = useAppSelector(selectionsState);
  const { networkStatus } = useAppSelector(mainState);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { hideLoader } = useLoader();
  const selection = mainPageSelections.find(el => el.uuid === selectionId);
  // || productPageSelections.find(el => el.uuid === selectionId);

  useEffect(() => {
    hideLoader();
  }, [hideLoader]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getStatus());
    }
  }, [networkStatus, dispatch]);

  useEffect(() => {
    const getCategoryProductsFn = async () => {
      const params = ProductsListTranslator.toRequestProductsBySelection(selectionId);
      await dispatch(getProductsList(params))
    };
    getCategoryProductsFn();
  }, [selectionId, dispatch]);

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate(-1);
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  return <Products title={selection?.title} />;
});
