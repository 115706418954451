import { memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { watermarkRules } from './watermark.style';
import { useTelegram } from 'src/utils/telegramProvider';
import {useTranslation} from '../../utils/i18n/hooks/useTranslation';

const Watermark = function Watermark() {
  const { css } = useStyle(watermarkRules);
  const { webApp } = useTelegram();
  const { t } = useTranslation();

  function openTeleportBot() {
    webApp.openTelegramLink('https://t.me/TeleportAppBot');
  }

  return (
    <div className={css.wrapper}>
      <p>{t('watermark.madeIn')}</p>
      <button className={css.button} onClick={openTeleportBot}>
        <img className={css.logo} src='/images/teleport-logo.png' />
      </button>
    </div>
  );
};

export default memo(Watermark);
