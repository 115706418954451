import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty';

export interface IModalPromocodeRuleKeys {
  wrapper: {};
  content: {};
  inputPromocode: {};
  incorrect: {};
  btnClear: {};
  footer: {};
}

interface IModalPromocodeRuleProps {
  incorrectCode: boolean;
}

export type IModalPromocodeRules = IRuleFn<IModalPromocodeRuleKeys, IModalPromocodeRuleProps>;

const modalPromocodeRuleFn: IModalPromocodeRules = props => {
  const { colorBlack, colorRed } = props.theme;
  const { incorrectCode } = props;

  return {
    wrapper: {
      position: 'relative',
    },
    content: {
      position: 'relative',
      ...padding(48, 0),
    },
    inputPromocode: {
      width: '100%',
      display: 'block',
      margin: '0 auto',
      fontWeight: '800',
      fontSize: '32px',
      lineHeight: '34px',
      textTransform: 'uppercase',
      border: 'none',
      outline: 'none',
      textAlign: 'center',
      color: incorrectCode ? colorRed : colorBlack,
    },
    incorrect: {
      textAlign: 'center',
      opacity: '0.4',
    },
    btnClear: {
      flexShrink: 0,
      '& svg': {
        width: 30,
        height: 30,
      },
    },
    footer: {
      display: 'flex',
      columnGap: '12px',
    },
  };
};

export const modalPromocodeRules = createCachedStyle(modalPromocodeRuleFn);
