import { FC, memo } from 'react';
import { IClasses } from 'src/utils/theme/fela.interface';
import { useStyle } from 'src/utils/theme/useStyle';
import Text from 'src/components/UI/Text/Text';
import { Button } from 'src/components/UI/Button/Button';
import { useModalState } from 'src/hooks/useModalState';
import { IModificatorListValue } from 'src/types/modificators';
// import { Price } from 'src/components/Price/Price';
import ModalModificatorList from './components/ModalModificatorList/ModalModificatorList';
import { modificatorListRules } from './modificatorList.style';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { changeChosenModifiers, productState, removeErrorModifiersUuid } from 'src/redux/slices/productDetailSlice';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { Price } from 'src/components/Price/Price';
import { ICompoundModifier } from 'src/blocks/Product/components/ProductModificators/ProductModificators';

interface IProps {
  extend?: IClasses;
  modifier: ICompoundModifier
}

export const ModificatorList: FC<IProps> = memo(function ModificatorList(props) {
  const { css } = useStyle(modificatorListRules);
  const { extend, modifier } = props;
  const [renderModal, activeModal, openModal, closeModal] = useModalState();

  const dispatch = useAppDispatch()
  const { chosenModifiers, errorModifiersUuids } = useAppSelector(productState)

  const { t } = useTranslation()  

  const changeValue = (value: IModificatorListValue) => {
    // Сбрасываем ошибку для модификаторов
    dispatch(removeErrorModifiersUuid(modifier.uuid))
    const modifiertoSend = modifier.variants.find(el => el.uuid === value.value)
    dispatch(changeChosenModifiers({
      uuid: modifiertoSend.uuid,
      title: modifiertoSend.title,
      modifierUuid: modifier.uuid
    }))
    setTimeout(() => {
      closeModal()
    }, 100);
  };

  const activeVariant = modifier.variants.find(variant => chosenModifiers.map(el => el.uuid).includes(variant.uuid))
  const affectPriceAmount = modifier.variants.find(variant => variant.uuid === activeVariant?.uuid)?.priceImpact


  return (
    <>
      <div className={[css.modificator, extend].join(' ')} onClick={openModal}>
        <div>
          <Text
            text={modifier.title}
            mod="title"
            textTransform="uppercase"
            fontSize={12}
            lineHeight="18px"
          /> 
          {errorModifiersUuids.includes(modifier.uuid) ? <p className={css.error}>{t('modificatorList.selectFromTheList')}</p>
            :
            <Text text={activeVariant?.title ?? t('modificatorList.chooseFromList')} mod="text" fontSize={12} lineHeight="14px" />
          }
        </div>
        <div className={css.rightBlock}>
          {affectPriceAmount > 0 && <Price amount={affectPriceAmount} hasSymbol isPositive fontSize={12} lineHeight={'18px'} fontWeight={700} />}
          <Button
            icon="chevron-right"
            propsStyles={{
              width: 20,
              height: 20,
              padding: 0,
              background: 'transparent',
            }}
            extend={css.btnOpen}
          />
        </div>
      </div>

      {renderModal && (
        <ModalModificatorList
          modificatorName={modifier.title}
          modificatorLink={modifier.descriptionLink}
          active={activeModal}
          onClose={closeModal}
          onChange={changeValue}
          variants={modifier.variants}
          chosenVariants={chosenModifiers}
        />
      )}
    </>
  );
});
