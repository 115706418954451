import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface ISelectionRuleKeys {
  section: {};
  header: {};
  headerInner: {};
  title: {};
  slide: {};
  skeletonWrapper: {};
  skeletonElementsWrapper: {};
  skeletonEl: {};
  skeletonElTitle: {};
  skeletonImage: {};
  skeletonTitle: {};
}

interface ISelectionRuleProps {
  marginBottom: number;
}

export type ISelectionRules = IRuleFn<ISelectionRuleKeys, ISelectionRuleProps>;

const SelectionRuleFn: ISelectionRules = props => {
  const { marginBottom } = props;

  return {
    section: {
      marginBottom,
    },
    header: {
      marginBottom: 16,
    },
    headerInner: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      fontWeight: 800,
      fontSize: 20,
      lineHeight: '26px',
    },
    slide: {
      width: 140,
    },
    skeletonTitle: {
      marginBottom: 16,
    },
    skeletonEl: {
      display: 'flex',
      flexDirection: 'column',
    },
    skeletonElTitle: {
      marginBottom: 2,
    },
    skeletonImage: {
      marginBottom: 8,
    },
    skeletonWrapper: {
      paddingLeft: 20,
      overflow: 'hidden',
    },
    skeletonElementsWrapper: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
    },
  };
};

export const SelectionRules = createCachedStyle(SelectionRuleFn);
