import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModalModificatorListRuleKeys {
  header: {};
  modalInner: {};
}

export type IModalModificatorListRules = IRuleFn<IModalModificatorListRuleKeys>;

const modalModificatorListRuleFn: IModalModificatorListRules = props => {
  const {} = props.theme;

  return {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: 20,
      paddingBottom: 12,
    },
    modalInner: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },
  };
};

export const modalModificatorListRules = createCachedStyle(modalModificatorListRuleFn);
