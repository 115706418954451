import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IMakeOrderKeys {
  header: {};
}

export type IMakeOrder = IRuleFn<IMakeOrderKeys>;

const MakeOrderRuleFn: IMakeOrder = props => {
  const {} = props.theme;
  const {} = props;

  return {
    header: {},
  };
};

export const MakeOrderRules = createCachedStyle(MakeOrderRuleFn);
