import { useContext } from 'react';
import { ThemeContext } from 'react-fela';
import { ITheme } from './theme.interface';
import { isEqual } from '../object/isEqual';
import { useMemoWith } from '../react-use/useMemoWith';

export const useTheme = () => {
  const theme = useContext(ThemeContext);

  const memoizedTheme = useMemoWith((theme ?? {}) as ITheme, isEqual);

  return {
    theme: memoizedTheme,
  };
};
