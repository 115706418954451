import { FC, memo, useEffect } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { useTheme } from 'src/utils/theme/useTheme';
import { Button } from '../UI/Button/Button';
import { storeNoteWorkingRules } from './storeNotWorking.style';
import { Layout } from '../Layout/Layout';
import useLoader from 'src/hooks/useLoader';
import { useTelegram } from 'src/utils/telegramProvider';
import { Icon } from '../Icon/Icon';
import { useAppSelector } from 'src/hooks/redux';
import { mainState } from 'src/redux/slices/mainSlice';
import {useTranslation} from '../../utils/i18n/hooks/useTranslation';

interface IProps {
  reasonText: string;
  supportLink?: string;
  accentColor?: string;
}

const StoreNotWorking: FC<IProps> = memo(function StoreNotWorking(props) {
  const { css } = useStyle(storeNoteWorkingRules);
  const { theme } = useTheme();
  const { hideLoader } = useLoader();
  const { reasonText, accentColor } = props;
  const { maintenance } = useAppSelector(mainState);
  const { t } = useTranslation()

  useEffect(() => {
    hideLoader();
  }, [hideLoader]);

  const Telegram = useTelegram();

  const connectToSupport = () => {
    const supportLink = maintenance.supportLink.replace(/^@/, '');
    Telegram.webApp.openTelegramLink(`https://t.me/${supportLink}`);
  };

  return (
    <div className={css.app}>
      <Layout footer={
        <footer className={css.footer}>
          <Button
            text={t('storeNotWorking.contactSupport')}
            propsStyles={{
              width: '100%',
              height: 62,
              background: theme.colorBlack,
            }}
            onClick={connectToSupport}
          />
        </footer>
      }>
        <div className={css.wrapper}>
          <Icon name="shop" properties={{ className: css.img, fill: accentColor }} />
          <span className={[css.text, css.title].join(' ')}>{t('storeNotWorking.theStoreIsTemporarilyUnavailable')}</span>
          <span className={[css.text, css.subtitle].join(' ')}>{reasonText}</span>
        </div>
      </Layout>
    </div>
  );
});

export default StoreNotWorking;
