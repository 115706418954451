import { IRuleFn } from '../../../utils/theme/fela.interface';
import { createCachedStyle } from '../../../utils/theme/createCachedStyle';
import { renderer } from '../../../styles/fela';

export interface ISkeletonImageRuleKeys {
  root: {};
}

export interface ISkeletonImageRuleProps {
  width: number | string;
  height: number | string;
  borderRadius: string;
  extend?: Partial<ISkeletonImageRuleKeys>
}

export type ISkeletonImageRules = IRuleFn<ISkeletonImageRuleKeys, ISkeletonImageRuleProps>;

const skeletonImageRuleFn: ISkeletonImageRules = props => {
  const { colorGrey } = props.theme;
  const { borderRadius, width, height } = props;

  const shimmerKeyframe = () => ({
    '0%': {backgroundPosition: '-200%'},
    '100%': {backgroundPosition: '200%'},
  });

  const animationName = renderer.renderKeyframe(shimmerKeyframe, {});

  return {
    root: {
      background: `linear-gradient(90deg, ${colorGrey} 25%, #efefef 50%, ${colorGrey} 75%)`,
      backgroundSize: '200% 100%',
      animationName: animationName,
      animationDuration: '1.2s',
      animationTimingFunction: 'ease-in-out',
      animationIterationCount: 'infinite',
      flexShrink: 0,
      width,
      height,
      borderRadius,
    }
  };
};

export const skeletonImageRules = createCachedStyle(skeletonImageRuleFn);
