import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';
import {borderRadius, padding} from '../../../utils/applyCSSProperty';

export interface IInputKeys {
  top: {};
  inputWrapper: {};
  input: {};
  symbol: {};
  message: {};
  iconBtn: {};
}

interface IInputProps {
  backgroundColor?: string;
  isInvaild: boolean;
  hasSymbol: boolean;
}

export type IInputRules = IRuleFn<IInputKeys, IInputProps>;

const inputRuleFn: IInputRules = props => {
  const { colorWhite, colorRgbBlack, mainColor, colorRed } = props.theme;
  const { backgroundColor, isInvaild, hasSymbol } = props;

  const bgColor = backgroundColor ? backgroundColor : colorWhite;

  return {
    top: {
      marginBottom: 8,
      display: 'flex',
      justifyContent: 'space-between',
    },

    inputWrapper: {
      position: 'relative',
    },

    input: {
      width: '100%',
      ...borderRadius(12),
      ...padding(20, hasSymbol ? 32 : 16, 20, 16),
      outline: 'none',
      backgroundColor: bgColor,
      ':-webkit-autofill': {
        WebkitBoxShadow: `inset 0 0 0 100px ${bgColor}`,
      },
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: isInvaild ? colorRed : bgColor,

      // txt
      fontSize: 16,
      lineHeight: '20px',
      fontWeight: 400,
      caretColor: mainColor,
    },

    message: {
      marginTop: 4,
      color: isInvaild ? colorRed : `rgba(${colorRgbBlack}, .4)`,
      fontWeight: '500',
      lineHeight: '14px',
      fontSize: 12,

      '& a': {
        fontWeight: 600,
        color: mainColor,
      },
    },

    symbol: {
      position: 'absolute',
      top: '50%',
      right: 16,
      transform: 'translateY(-50%)',
    },
    iconBtn: {
      position: 'absolute',
      top: '50%',
      right: 16,
      transform: 'translateY(-50%)',
    },
  };
};

export const inputRules = createCachedStyle(inputRuleFn);
