import { v4 } from 'uuid';
import { keyStyleSelector } from './keyStyleSelector';
import { normalizeStyleName } from './normalizeStyleName';
import {
  ICachedStyle,
  IClasses,
  IEnhancedRenderer,
  IExtend,
  IParametricCachedStyle,
  IRules,
  StyleProps,
} from './fela.interface';
import { combineRules } from './combineRules';

export interface StyleFunc<R> {
  (renderer: IEnhancedRenderer): R;
}

export interface ParametricStyleFunc<P, R> {
  (props: P, renderer: IEnhancedRenderer): R;
}

export function createCachedStyle<R extends object>(styleFunc: StyleFunc<R>): ICachedStyle<R>;

export function createCachedStyle<P extends object, R extends object>(
  styleFunc: ParametricStyleFunc<P, R>,
): IParametricCachedStyle<R, P>;

export function createCachedStyle<Props extends object, Rules extends object>(
  styleFunc: (props?: Props, renderer?: IEnhancedRenderer) => Rules,
) {
  const id = v4();

  const styleCache = {} as IRules<Rules>;
  const rulesCache = {} as IRules<Rules>;
  const cssCache = {} as IClasses<Rules>;

  const funcName =
    process.env.NODE_ENV === 'development' ? normalizeStyleName(styleFunc.name) : undefined;

  const generateStyles = (props: StyleProps<Props, Rules>, renderer: IEnhancedRenderer) => {
    const key = keyStyleSelector(id, props);

    if (styleCache[key] !== undefined) {
      return {
        key,
        rules: styleCache[key],
      };
    }

    styleCache[key] = styleFunc(props, renderer);

    return {
      key,
      rules: styleCache[key],
    };
  };

  const getRules = (props: StyleProps<Props, Rules>, renderer: IEnhancedRenderer) => {
    const { extend } = props ?? {};

    const { rules, key } = generateStyles(props, renderer);

    if (rulesCache[key] !== undefined) {
      return rulesCache[key];
    }

    const result = {} as IExtend<Rules>;

    const keys = Object.keys(rules);

    for (let i = 0; i < keys.length; i++) {
      const ruleName = keys[i] as string;

      const combinedRule = extend
        ? combineRules(rules[ruleName], extend?.[ruleName]?.rule ?? {})
        : rules[ruleName];

      result[ruleName] = {
        key: `${key}+${ruleName}`,
        name: extend?.[ruleName]?.name ? `${extend[ruleName].name}_${funcName}` : funcName,
        rule: combinedRule as Rules[Extract<keyof Rules, string>],
      };
    }

    rulesCache[key] = result;

    return result;
  };

  const getStyle = (props: StyleProps<Props, Rules>, renderer: IEnhancedRenderer) => {
    const { extend = {}, ...restProps } = props ?? {};
    const nextProps = { ...restProps, extend } as Props;

    const { key } = generateStyles(nextProps, renderer);

    if (cssCache[key] !== undefined) {
      return {
        css: cssCache[key],
      };
    }

    cssCache[key] = {};

    const rules = getRules(nextProps, renderer);

    const keys = Object.keys(rules);

    for (let i = 0; i < keys.length; i++) {
      const ruleName = keys[i] as string;
      const rule = rules[ruleName];

      const currentRule = () => rule.rule;

      if (process.env.NODE_ENV === 'development' && renderer.prettySelectors) {
        Object.assign(currentRule, {
          selectorPrefix: `${rule.name}_${ruleName.toString()}_`,
        });
      }

      cssCache[key][ruleName] = renderer.renderRule(currentRule, {});
    }

    return {
      css: cssCache[key],
    };
  };

  return {
    getRules: getRules as any,
    getStyle: getStyle as any,
  };
}
