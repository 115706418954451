import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IRangeSliderRuleKeys {
  rangeSlider: {};
  controller: {};
  control: {};
  rail: {};
  railInner: {};
  inputWrapper: {};
  input: {};
  priceBlock: {};
  priceRow: {};
}

export type IRangeSliderRules = IRuleFn<IRangeSliderRuleKeys>;

const rangeSliderRuleFn: IRangeSliderRules = props => {
  const { colorWhite, colorBlack } = props.theme;

  const controlSize = 20;

  const trackStyles = {
    appearance: 'none',
    background: 'transparent',
    border: 'transparent',
  };

  const thumbStyles = {
    appearance: 'none',
    pointerEvents: 'all',
    width: 20,
    height: 20,
    borderRadius: 0,
    border: '0 none',
    cursor: 'grab',
    backgroundColor: 'red',

    ':active': {
      cursor: 'grabbing',
    },
  };

  return {
    rangeSlider: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      height: controlSize,
      marginLeft: `calc(${controlSize}px/2)`,
      marginRight: `calc(${controlSize}px/2)`,
    },
    inputWrapper: {
      width: `calc(100% + ${controlSize}px)`,
      marginLeft: `calc(${controlSize}px/-2)`,
      marginRight: `calc(${controlSize}px/-2)`,
      position: 'absolute',
      height: controlSize,
    },
    controller: {
      width: '100%',
      position: 'absolute',
      height: controlSize,
    },
    input: {
      position: 'absolute',
      width: '100%',
      pointerEvents: 'none',
      appearance: 'none',
      height: '100%',
      opacity: '0',
      zIndex: 3,
      padding: 0,

      '::-ms-track': trackStyles,
      '::-moz-range-track': trackStyles,
      ':focus::-webkit-slider-runnable-track': trackStyles,
      '::-ms-thumb': thumbStyles,
      '::-moz-range-thumb': thumbStyles,
      '::-webkit-slider-thumb': thumbStyles,

      // "::-webkit-slider-thumb": {
      //   width: "40px",
      //   height: "40px",
      // },
    },

    rail: {
      position: 'absolute',
      width: '100%',
      top: '50%',
      transform: 'translateY(-50%)',
      height: 2,
      borderRadius: '5px',
      backgroundColor: 'grey',
    },
    railInner: {
      backgroundColor: colorBlack,
      position: 'absolute',
      height: '100%',
    },
    control: {
      pointerEvents: 'none',
      width: controlSize,
      height: controlSize,
      borderRadius: '50%',
      position: 'absolute',
      border: `2px solid ${colorBlack}`,
      backgroundColor: colorWhite,
      top: '50%',
      transform: 'translate3d(0, -50%, 0)',
      zIndex: 2,
      marginLeft: '-10px',
    },

    // price
    priceBlock: {
      display: 'flex',
      marginBottom: '4px',
      justifyContent: 'space-between',

      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '16px',

      '& span': {
        fontWeight: 700,
      },
    },
    priceRow: {
      display: 'flex',
      alignItems: 'center',
    }
  };
};

export const rangeSliderRules = createCachedStyle(rangeSliderRuleFn);
