import { IDirectCheckoutParams } from '../api/directCheckout';
import {DeliveryType} from '@teleport/schemas-protobuf';
import {
  CheckoutForm,
  CheckoutForm_Digital,
  CheckoutForm_Physical,
  CheckoutForm_Request
} from '@teleport/schemas-protobuf/port/v1/port_order_pb';
import { ICartCheckoutParams } from '../api/cart/cartCheckout';

export const CheckoutTranslator = {
  toCheckoutRequest(params: IDirectCheckoutParams | ICartCheckoutParams, deliveryType: DeliveryType) {
    const {phone, name, email} = params.formData;
    const deliveryCase = DeliveryType[deliveryType].toLowerCase();
    const checkoutForm = new CheckoutForm({checkoutForm: {value: undefined, case: undefined}});

    if(deliveryCase === 'request') {
      checkoutForm.checkoutForm.case = deliveryCase;
      checkoutForm.checkoutForm.value = new CheckoutForm_Request({ phone, fullName: name })
    } else if(deliveryCase === 'digital') {
      checkoutForm.checkoutForm.case = deliveryCase;
      checkoutForm.checkoutForm.value = new CheckoutForm_Digital({ phone, fullName: name, email })
    } else if(deliveryCase === 'physical') {
      checkoutForm.checkoutForm.case = deliveryCase;
      checkoutForm.checkoutForm.value = new CheckoutForm_Physical({ phone, fullName: name, email })
    }

    if ('product' in params) {
      return {
        product: {
          productUuid: params.product.productUuid,
          quantity: params.product.quantity,
          chosenModifiers: params.product.chosenModifiers
        },
        checkoutForm
      };
    }

    return {
      checkoutForm
    }
  },
};
