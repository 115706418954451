import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IButtonBasketRuleKeys {
  counter: {};
}

export interface IButtonBasketProps {
  lotOfGoods?: boolean;
}

export type IButtonBasketRules = IRuleFn<IButtonBasketRuleKeys, IButtonBasketProps >;

const buttonBasketRuleFn: IButtonBasketRules = props => {
  const { colorWhite, colorBlack } = props.theme;
  const { lotOfGoods } = props;

  return {
    counter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: lotOfGoods ? 6 : 10,
      right: lotOfGoods ? 6 : 8,
      padding: 4,
      width: lotOfGoods ? 27 : 20,
      height: lotOfGoods ? 27 : 20,
      borderRadius: '50%',
      background: colorWhite,

      // txt
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 700,
      textAlign: 'center',
      color: colorBlack,
    },
  };
};

export const buttonBasketRules = createCachedStyle(buttonBasketRuleFn);
