import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';
import {borderRadius, padding} from '../../utils/applyCSSProperty';

export interface IMakeOrderFormKeys {
  wrapper: {};
  fieldWrapper: {};
  footer: {};
  input: {};
  inputErrorMessage: {};
  label: {};
}

interface IMakeOrderFormProps {
  footerHeight: number;
}

export type IMakeOrderForm = IRuleFn<IMakeOrderFormKeys, IMakeOrderFormProps>;

const MakeOrderFormRuleFn: IMakeOrderForm = props => {
  const { colorWhite, mainColor, colorRed } = props.theme;
  const { footerHeight } = props;

  return {
    wrapper: {
      minHeight: '100%',
      paddingBottom: footerHeight,
    },
    fieldWrapper: {
      ...padding(12, 0),
    },
    label: {
      marginBottom: 8,
    },
    input: {
      width: '100%',
      ...borderRadius(12),
      ...padding(20, 16, 20, 16),
      outline: 'none',
      backgroundColor: colorWhite,
      ':-webkit-autofill': {
        WebkitBoxShadow: `inset 0 0 0 100px ${colorWhite}`,
      },
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: colorWhite,

      // txt
      fontSize: 16,
      lineHeight: '20px',
      fontWeight: 400,
      caretColor: mainColor,
    },
    inputErrorMessage: {
      marginTop: 4,
      color: colorRed,
    },
    footer: {
      width: '100%',
      position: 'fixed',
      left: 0,
      bottom: 0,
      ...padding(12, 16, 30),
      backgroundColor: colorWhite,
    },
  };
};

export const MakeOrderFormRules = createCachedStyle(MakeOrderFormRuleFn);
