import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductTranslator } from '../translators/productTranslator';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import { RootState } from '../store';
import { createAppAsyncThunk } from '../../hooks/redux';
import { productToFavoriteBind } from './favouritesSlice';
import { ClientCatalogProductFull } from '@teleport/schemas-protobuf';

export interface IModifierVariant {
  uuid: string
  title: string
  modifierUuid?: string
}

interface IProductDetail {
  product: ClientCatalogProductFull | null;
  networkStatus: NetworkStatus;
  chosenModifiers: IModifierVariant[];
  errorModifiersUuids: string[]
  needRedirectToProductPage: boolean;
}

const initialState: IProductDetail = {
  product: null,
  networkStatus: NetworkStatus.None,
  chosenModifiers: [],
  errorModifiersUuids: [],
  needRedirectToProductPage: true,
};

export const getProductDetail = createAppAsyncThunk(
  'product/detail',
  async (productUuid: string, thunkAPI) => {
    const result = await thunkAPI.extra.portApi.clientCatalogProductDetail({ productUuid });
    return ProductTranslator.fromStatusResponse(result);
  },
);

export const productDetailSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    changeChosenModifiers: (state, action: PayloadAction<IModifierVariant>) => {
      const variant = action.payload;  
      const modifierIndex = state.chosenModifiers.findIndex(item => item.modifierUuid === variant.modifierUuid);  
      const index = state.chosenModifiers.findIndex(item => item.uuid === variant.uuid); 
      if (index !== -1) {
        state.chosenModifiers = state.chosenModifiers.filter(el => el.uuid !== variant.uuid);
      } else {
        if (modifierIndex === -1 || !variant.modifierUuid){
          state.chosenModifiers.push(variant);
        }else {
          state.chosenModifiers = state.chosenModifiers.map(el => 
            el.modifierUuid === variant.modifierUuid ? variant : el
          )
        }

      }
    },
    clearChosenModifiers: state => {
      state.chosenModifiers = [];
    },
    setErrorModifiersUuids: (state, action: PayloadAction<string[]>) => {
      state.errorModifiersUuids = action.payload
    },
    removeErrorModifiersUuid: (state, action: PayloadAction<string>) => {
      state.errorModifiersUuids = state.errorModifiersUuids.filter(uuid => uuid !== action.payload) 
    },
    resetNetworkStatus: state => {
      state.networkStatus = NetworkStatus.None
    },
    setNeedRedirectToProductPage: (state, action) => {
      state.needRedirectToProductPage = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getProductDetail.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getProductDetail.fulfilled, (state, action) => {
      state.product = action.payload;
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getProductDetail.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });

    builder.addCase(productToFavoriteBind.fulfilled, (state, action) => {
      if (state.product) state.product.isFavorite = action.payload.isFavourite;
    });
  },
});

export const { changeChosenModifiers, setNeedRedirectToProductPage, resetNetworkStatus, setErrorModifiersUuids, removeErrorModifiersUuid, clearChosenModifiers } = productDetailSlice.actions

export const productState = (state: RootState) => state[productDetailSlice.name];
