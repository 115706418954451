import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IChipRuleKeys {
  chip: {};
  input: {};
  text: {};
}

interface IChiprProps {
  checked: boolean;
}

export type IChipRules = IRuleFn<IChipRuleKeys, IChiprProps>;

const chipRuleFn: IChipRules = props => {
  const { colorGrey, colorBlack, colorWhite } = props.theme;
  const { checked } = props;

  return {
    chip: {
      display: 'flex',
      columnGap: 4,
      alignItems: 'center',
      padding: '8px 12px',
      borderRadius: '8px',
      backgroundColor: checked? colorBlack : colorGrey,
      color: checked? colorWhite : 'inherit',
    },
    input: {
      position: 'absolute',
      zIndex: '-1',
      opacity: 0,
    },
    text: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '16px',
      whiteSpace: 'nowrap',
    },
  };
};

export const chipRules = createCachedStyle(chipRuleFn);
