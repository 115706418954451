import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { storyRules } from './story.style';
import { ImageComponent } from 'src/components/ImageComponent/ImageComponent';
import { useTelegram } from 'src/utils/telegramProvider';

interface IProps {
  className?: string;
  text: string;
  url: string;
  backgroundImage: string;
}

export const Story: FC<IProps> = memo(function Story(props) {
  const { text, url, backgroundImage } = props;
  const { css } = useStyle(storyRules);
  const { webApp } = useTelegram();

  function openStory() {
    webApp.openLink(url, { try_instant_view: true });
  }

  return (
    <button className={css.card} onClick={openStory}>
      <span className={css.text}>{text}</span>
      <ImageComponent src={backgroundImage} addClass={css.backgroundImage} />
    </button>
  );
});
