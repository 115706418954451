import { IRuleFn } from '../../../utils/theme/fela.interface';
import { renderer } from '../../../styles/fela';
import { createCachedStyle } from '../../../utils/theme/createCachedStyle';

export interface ISkeletonTextRuleKeys {
  root: {};
}

export interface ISkeletonTextRuleProps {
  width: number | string;
  height: number | string;
  extend?: Partial<ISkeletonTextRuleKeys>
}

export type ISkeletonTextRules = IRuleFn<ISkeletonTextRuleKeys, ISkeletonTextRuleProps>;

const skeletonTextRuleFn: ISkeletonTextRules = props => {
  const { colorGrey } = props.theme;
  const { width, height } = props;

  const shimmerKeyframe = () => ({
    '0%': {backgroundPosition: '-200%'},
    '100%': {backgroundPosition: '200%'},
  });

  const animationName = renderer.renderKeyframe(shimmerKeyframe, {});

  return {
    root: {
      background: `linear-gradient(90deg, ${colorGrey} 25%, #efefef 50%, ${colorGrey} 75%)`,
      backgroundSize: '200% 100%',
      animationName: animationName,
      animationDuration: '1.2s',
      animationTimingFunction: 'ease-in-out',
      animationIterationCount: 'infinite',
      borderRadius: 4,
      width,
      height,
    }
  };
};

export const skeletonTextRules = createCachedStyle(skeletonTextRuleFn);
