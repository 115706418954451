import { FC, memo, useEffect, useRef, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import Text from 'src/components/UI/Text/Text';
import { productDescriptionRules } from './ProductDescription.style';
import { Icon } from 'src/components/Icon/Icon';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

interface IProps {
  text: string;
}

export const ProductDescription: FC<IProps> = memo(function ProductDescription(props) {
  const [open, setOpen] = useState(false);
  const [needButton, setNeedButton] = useState(false);
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const descriptionRef2 = useRef<HTMLParagraphElement>(null);
  const { text } = props;

  const { t } = useTranslation()

  useEffect(() => {
    if (descriptionRef2.current.clientHeight > 60) setNeedButton(true)
  }, [descriptionRef, text]);

  const { css } = useStyle(productDescriptionRules, {
    open,
  });

  const toggleOpenState = () => {
    setOpen(!open);
  };
  return (
    <div className={css.wrapper}>
      <Text text={t('productDescription.description')} mod="title" extend={css.title} />
      <Text mod="text" fontSize={14} lineHeight="20px" text={text} extend={css.textHidden} ref={descriptionRef2} whiteSpace={'pre-wrap'}/>
      <Text
        mod="text"
        fontSize={14}
        lineHeight="20px"
        text={text}
        extend={css.text}
        ref={descriptionRef}
        whiteSpace={'pre-wrap'}
      />
      {needButton && (
        <button onClick={toggleOpenState} className={css.button}>
          {open ? t('productDescription.hideDescription') : t('productDescription.showDescription')}
          <Icon name="chevron-right" properties={{ className: css.icon }} />
        </button>
      )}
    </div>
  );
});
