import Container from '../../components/Container/Container';
import { Button } from '../../components/UI/Button/Button';
import { SkeletonText } from '../../components/UI/SkeletonText/SkeletonText';
import { useStyle } from '../../utils/theme/useStyle';
import { cartRules } from './сart.style';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';

const CartInfoSkeleton = () => {
  const { css } = useStyle(cartRules);
  const { t } = useTranslation();

  return (
    <div className={css.cart_info}>
      <Container>
        <div className={css.summary_row}>
          <div
            className={css.summary_row_inner}>
            <p>{t('cart.promoCode')}</p>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.909 10.7143L15.9795 10.7143L11.1504 15.5434C10.8096 15.8842 10.8096 16.4524 11.1504 16.7932C11.4913 17.1341 12.0594 17.1341 12.4003 16.7932L18.7443 10.4492C19.0852 10.1083 19.0852 9.54021 18.7443 9.19934L12.4003 2.85531C12.2299 2.68487 12.0026 2.59018 11.7754 2.59018C11.5481 2.59018 11.3209 2.68487 11.1504 2.8553C10.8096 3.19618 10.8096 3.7643 11.1504 4.10518L15.9795 8.93421L1.89006 8.93421C1.39768 8.93421 1 9.3319 1 9.82427C1.01894 10.3166 1.41662 10.7143 1.909 10.7143Z"
                fill="#1C1916"
              />
            </svg>
          </div>
        </div>
        <div className={css.summary_row}>
          <div className={css.summary_row_inner}>
            <p>{t('cart.orderTotal')}</p>
            <SkeletonText width={50} height={18} />
          </div>
          <div className={css.summary_row_inner}>
            <p>{t('cart.discount')}</p>
            <SkeletonText width={50} height={18} />
          </div>
        </div>
        <div className={css.to_pay}>
          <p>{t('cart.goToPayment')}</p>
          <SkeletonText width={100} height={22} />
        </div>
        <Button
          disabled={true}
          text={t('cart.checkout')}
          propsStyles={{width: '100%', height: 62}}
        />
      </Container>
    </div>
  )
};

export default CartInfoSkeleton;
