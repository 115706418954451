import { MainPage } from 'src/pages/MainPage/MainPage';
import { RouteObject } from 'react-router-dom';
import NotFoundPage from 'src/pages/NotFoundPage/NotFoundPage';
import Suggestions from 'src/pages/Suggestions/Suggestions';
import Favourites from 'src/pages/Favourites/Favourites';
import { ProductPage } from 'src/pages/ProductPage/ProductPage';
import { SubcategoryPage } from 'src/pages/SubcategoryPage/SubcategoryPage';
import { CategoryPage } from 'src/pages/CategoryPage/CategoryPage';
import Catalog from 'src/pages/Catalog/Catalog';
import { Cart } from 'src/pages/Сart/Сart';
import { MakeOrder } from 'src/pages/MakeOrder/MakeOrder';
import { MakeSingleOrder } from 'src/pages/MakeSingleOrder/MakeSingleOrder';
import { SelectionPage } from 'src/pages/SelectionPage/SelectionPage';

export enum AppRoutes {
  MAIN = 'main',
  SUGGESTIONS = 'suggestions',
  FAVOURITES = 'favourites',
  PRODUCT = 'product',
  SUBCATEGORY = 'subcategory',
  CATEGORY = 'category',
  SELECTION = 'selection',
  CATALOG = 'catalog',
  CART = 'cart',
  MAKE_ORDER = 'makeOrder',
  MAKE_SINGLE_ORDER = 'makeSingleOrder',
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.MAIN]: '/',
  [AppRoutes.SUGGESTIONS]: '/suggestions',
  [AppRoutes.FAVOURITES]: '/favourites',
  [AppRoutes.PRODUCT]: '/product/:productId',
  [AppRoutes.SUBCATEGORY]: '/subcategory/:categoryId/:subCategoryId',
  [AppRoutes.CATEGORY]: '/category/:categoryId',
  [AppRoutes.CART]: '/cart',
  [AppRoutes.MAKE_ORDER]: '/make-order',
  [AppRoutes.MAKE_SINGLE_ORDER]: '/make-single-order/:productId',
  [AppRoutes.CATALOG]: '/catalog',
  [AppRoutes.SELECTION]: '/selection/:selectionId',
};

export const routeConfig: RouteObject[] = [
  {
    path: RoutePath.main,
    element: <MainPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath.suggestions,
    element: <Suggestions />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath.favourites,
    element: <Favourites />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath.product,
    element: <ProductPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath.subcategory,
    element: <SubcategoryPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath.category,
    element: <CategoryPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath.catalog,
    element: <Catalog />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath.cart,
    element: <Cart />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath.makeOrder,
    element: <MakeOrder />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath.makeSingleOrder,
    element: <MakeSingleOrder />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath.selection,
    element: <SelectionPage />,
    errorElement: <NotFoundPage />,
  },
];
