import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import { Modifier } from '@teleport/schemas-protobuf';
import { createAppAsyncThunk } from 'src/hooks/redux';

interface ModifiersState {
  networkStatus: NetworkStatus
  modifiers: Modifier[];
}

const initialState: ModifiersState = {
  modifiers: [],
  networkStatus: NetworkStatus.None
};

export const getAllModifiers = createAppAsyncThunk<Modifier[]>('modifiers/getAllModifiers', 
  async (_, thunkAPI) => {
    const result = await thunkAPI.extra.portApi.modifierList({})
    return result.modifiers
  }
)

export const modifiersSlice = createSlice({
  name: 'modifiers',
  initialState,
  reducers: {
    resetModifiersNetworkStatus: state => {
      state.networkStatus = NetworkStatus.None
    },
  },
  extraReducers: builder => {
    builder.addCase(getAllModifiers.pending, state => {
      state.networkStatus = NetworkStatus.Loading
    });
    builder.addCase(getAllModifiers.fulfilled, (state, action) => {
      state.networkStatus = NetworkStatus.Done
      state.modifiers = action.payload
    });
    builder.addCase(getAllModifiers.rejected, state => {
      state.networkStatus = NetworkStatus.Failed
    })
  },
});

export const { resetModifiersNetworkStatus } = modifiersSlice.actions;
export const modifiersState = (state: RootState) => state[modifiersSlice.name];
