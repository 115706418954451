import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IStoryRuleKeys {
  card: {};
  text: {};
  backgroundImage: {};
}

export type IStoryRules = IRuleFn<IStoryRuleKeys>;

const storyRuleFn: IStoryRules = props => {
  const { colorWhite, colorRgbBlack } = props.theme;
  return {
    card: {
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'start',
      position: 'relative',
      width: 100,
      height: 140,
      overflow: 'hidden',
      padding: 4,
      borderRadius: 8,
      border: 'none',
      outline: 'none',

      '::before': {
        display: 'block',
        content: '""',
        position: 'absolute',
        pointerEvents: 'none',
        inset: 0,
        zIndex: 1,
        backgroundColor: `rgba(${colorRgbBlack}, 0.5)`,
      },
    },
    text: {
      zIndex: 1,
      display: 'block',
      fontWeight: 600,
      fontSize: 10,
      lineHeight: '12px',
      whiteSpace: 'pre-wrap',
      textAlign: 'start',
      color: colorWhite,
    },
    backgroundImage: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  };
};

export const storyRules = createCachedStyle(storyRuleFn);
