import { ReactNode, memo, useEffect, useState } from 'react';
import { getDocumentHeight } from 'src/utils/getDocumentHeight';
import { useStyle } from 'src/utils/theme/useStyle';
import { footerRules } from './footer.style';
import { useAppSelector } from 'src/hooks/redux';
import { configState } from 'src/redux/slices/configSlice';
import ButtonBasket from '../UI/ButtonBasket/ButtonBasket';
import { useLocation } from 'react-router-dom';
import { Button } from '../UI/Button/Button';
import { RoutePath } from 'src/providers/AppRouter/routeConfig';
import { useTheme } from 'src/utils/theme/useTheme';

interface IProps {
  children: ReactNode;
  propsStyles?: IStyles;
  staticStyle?: boolean;
  staticFooter?: boolean;
  hasFavoriteButton?: boolean;
}

interface IStyles {
  height?: number;
  padding?: string;
  backgroundColor?: string;
}

const Footer = function Footer({ children, staticFooter = false, propsStyles, hasFavoriteButton = true }: IProps) {
  const [endDocument, setEndDocument] = useState(false);
  const location = useLocation();
  const { config } = useAppSelector(configState);

  const { css } = useStyle(footerRules, {
    height: propsStyles?.height,
    padding: propsStyles?.padding,
    backgroundColor: propsStyles?.backgroundColor,
    endDocument,
    staticFooter,
  });

  const { theme } = useTheme();
  const renderButtonBasket = config.cartEnabled
    && location.pathname !== RoutePath.makeOrder
    && location.pathname !== RoutePath.makeSingleOrder
    && location.pathname !== RoutePath.catalog
    && location.pathname !== RoutePath.cart;

  useEffect(() => {
    const scrollHeight = getDocumentHeight();
    const windowHeight = document.documentElement.clientHeight;

    if (scrollHeight - window.scrollY - windowHeight <= 75) {
      setEndDocument(true);
    }

    const checkScrollPosition = () => {
      if (scrollHeight - window.scrollY - windowHeight <= 75) {
        setEndDocument(true);
      } else {
        setEndDocument(false);
      }
    };

    document.addEventListener('scroll', checkScrollPosition);

    return () => {
      document.removeEventListener('scroll', checkScrollPosition);
    };
  }, []);

  return (
    <footer className={css.footer}>
      {children}
      {renderButtonBasket && <ButtonBasket href="/cart" />}
      {hasFavoriteButton
        && <Button
          icon="heart"
          href={RoutePath.favourites}
          extend={css.favoriteBtn}
          propsStyles={{
            background: theme.colorGrey,
            width: 62,
            height: 62,
            padding: 9,
          }}
        />}
    </footer>
  );
};

export default memo(Footer);
