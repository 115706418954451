import {
  Filter_BoolFilter,
  Filter_MultiselectFilter,
  Filter_RangeFilter,
} from '@teleport/schemas-protobuf';

export function isBoolFilter(_value: any, blockCase: string): _value is Filter_BoolFilter {
  return blockCase === 'boolFilter';
}

export function isMultiselectFilter(
  _value: any,
  blockCase: string,
): _value is Filter_MultiselectFilter {
  return blockCase === 'multiselectFilter';
}

export function isRangeFilter(_value: any, blockCase: string): _value is Filter_RangeFilter {
  return blockCase === 'rangeFilter';
}
