import { FC, memo } from 'react';
import { useStyle } from '../../utils/theme/useStyle';
import { storiesRules } from './stories.style';
import { SkeletonImage } from '../../components/UI/SkeletonImage/SkeletonImage';

interface IProps {
  className?: string;
  propsStyles?: any;
}

export const StoriesSkeleton: FC<IProps> = memo(function StoriesSkeleton(props) {
  const { propsStyles } = props;
  const { css, rules } = useStyle(storiesRules, {
    paddingTop: propsStyles?.paddingTop,
    paddingBottom: propsStyles?.paddingBottom,
  });

  return (
    <div className={css.skeletonWrapper}>
      <SkeletonImage width={100} height={140} borderRadius={'8px'} extend={{root: rules.skeletonCard }} />
      <SkeletonImage width={100} height={140} borderRadius={'8px'} extend={{root: rules.skeletonCard }} />
      <SkeletonImage width={100} height={140} borderRadius={'8px'} extend={{root: rules.skeletonCard }} />
      <SkeletonImage width={100} height={140} borderRadius={'8px'} extend={{root: rules.skeletonCard }} />
    </div>
  );
});
