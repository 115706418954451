import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IMainPageRuleKeys {
  shopTitle: {};
  shopDesc: {};
  collection: {};
}

export type IMainPageRules = IRuleFn<IMainPageRuleKeys>;

const mainPageRuleFn: IMainPageRules = () => ({
  shopTitle: {
    display: 'block',
    marginBottom: 8,
  },

  shopDesc: {
    marginBottom: 20,
  },

  collection: {
    padding: '16px 0',
  },
});

export const mainPageRules = createCachedStyle(mainPageRuleFn);
