import { useRef } from 'react';

export const useScrollBlock = () => {
  const scroll = useRef(false);

  const blockScroll = () => {
    if (typeof document === 'undefined') return;

    const html = document.documentElement
    const rootLayout = document.querySelector<HTMLDivElement>('.root-layout');

    if (!rootLayout?.style || scroll.current) return;

    const scrollBarWidth = window.innerWidth - html.clientWidth;
    const bodyPaddingRight = parseInt(window.getComputedStyle(rootLayout).getPropertyValue('padding-right')) || 0;

    rootLayout.style.position = 'relative';
    rootLayout.style.overflow = 'hidden';
    rootLayout.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

    scroll.current = true;
  };

  const allowScroll = () => {
    if (typeof document === 'undefined') return;

    const rootLayout = document.querySelector<HTMLDivElement>('.root-layout');

    if (!rootLayout?.style || !scroll.current) return;

    rootLayout.style.position = '';
    rootLayout.style.overflow = '';
    rootLayout.style.paddingRight = '';

    scroll.current = false;
  };

  return [blockScroll, allowScroll] as const;
};
