import { CatalogMetadataResponse } from '@teleport/schemas-protobuf';

export const categoriesTranslator = {
  fromStatusResponse(response: CatalogMetadataResponse) {
    const categories = response.categories
      .map(item => {
        if (item.category.type.case === 'rootCategory') {
          return {
            category: item.category.type.value,
            productsCount: item.productsCount,
          };
        }
        return null;
      })
      .filter(Boolean);
    const subcategories = response.categories
      .map(item => {
        if (item.category.type.case === 'subCategory') {
          return {
            subCategory: item.category.type.value,
            productsCount: item.productsCount,
          };
        }
        return null;
      })
      .filter(Boolean);

    const translatorResult = categories.map(item => {
      const childSubcategories = subcategories.filter(
        el => item.category.uuid === el.subCategory.categoryUuid,
      );
      return { ...item, subCategories: childSubcategories };
    });

    return translatorResult;
  },
};
