import { IRuleFn } from '../../utils/theme/fela.interface';
import { createCachedStyle } from '../../utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty';

export interface IContainerRuleKeys {
  root: {};
}

interface IContainerRuleProps {
  paddingTop?: number;
  paddingBottom?: number;
}

export type IContainerRules = IRuleFn<IContainerRuleKeys, IContainerRuleProps>;

const containerRuleFn: IContainerRules = props => {
  const { paddingTop = 0, paddingBottom = 0 } = props;
  const {} = props.theme;

  return {
    root: {
      ...padding(paddingTop, 16, paddingBottom, 16),
      width: '100%',
    },
  };
};

export const containerRules = createCachedStyle(containerRuleFn);
