import { memo, useEffect, useMemo } from 'react';
import { Layout } from 'src/components/Layout/Layout';
import { useStyle } from 'src/utils/theme/useStyle';
import { useTheme } from 'src/utils/theme/useTheme';
import Container from 'src/components/Container/Container';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import { catalogRules } from './catalog.style';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import useLoader from 'src/hooks/useLoader';
import { categoriesState } from '../../redux/slices/categoriesSlice';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate } from 'react-router-dom';
import { configState } from 'src/redux/slices/configSlice';
import { resetProducts } from 'src/redux/slices/productsListSlice';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import Header from '../../components/Header/Header';
import { getCatalogMetadataWithProducts } from 'src/redux/api/getCatalogMetadataWithProducts';
import { CategoryCard } from '../../components/СategoryCard/СategoryCard';
import { CategoryCardSkeleton } from '../../components/СategoryCard/CategoryCardSkeleton';

const Catalog = function Main() {
  const { css } = useStyle(catalogRules);
  const { theme } = useTheme();

  const {  hideLoader } = useLoader();
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const { categories, networkStatus } = useAppSelector(categoriesState);
  const { config } = useAppSelector(configState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate('/');
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  useEffect(() => {
    hideLoader();
  }, [hideLoader])

  useEffect(() => {
    dispatch(getCatalogMetadataWithProducts());
  }, [dispatch]);

  useEffect(() => {
    dispatch(resetProducts())
  }, [dispatch])

  const { t } = useTranslation()

  useEffect(() => {
    if (categories.length === 1) {
      navigate(`/category/${categories[0].category.uuid}`, { replace: true }); 
    }
  }, [categories, navigate]);

  const content = useMemo(() => {
    if (networkStatus === NetworkStatus.Loading) {
      return (
        <>
          <CategoryCardSkeleton />
          <CategoryCardSkeleton />
          <CategoryCardSkeleton />
          <CategoryCardSkeleton />
          <CategoryCardSkeleton />
        </>
      )
    } else {
      return (
        <>
          {categories.map(category => (
            <CategoryCard key={category.category.uuid} categoryData={category} />
          ))}
        </>
      )
    }
  }, [
    categories,
    networkStatus,
  ])



  return (
    <Layout
      header={<Header />}
      footer={
        config.cartEnabled ? (
          <Footer>
            <Button
              href="/cart"
              text={t('catalog.cart')}
              propsStyles={{
                width: '100%',
                background: theme.mainColor,
                color: theme.colorWhite,
              }}
            />
          </Footer>
        ) : null
      }
    >
      <Container extend={css.container}>
        <div className={css.categoriesWrapper}>
          {content}
        </div>
      </Container>
    </Layout>
  );
};

export default memo(Catalog);
