import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { FavoriteButtonRules } from './FavoriteButton.style';
import { Icon } from 'src/components/Icon/Icon';

interface IProps {
  extend?: string;
  isFavorite: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const FavoriteButton: FC<IProps> = memo(function FavoriteButton(props) {
  const { extend, isFavorite, onClick } = props;
  const { css } = useStyle(FavoriteButtonRules, {
    isFavorite,
  });

  return (
    <button className={[css.button, extend].join(' ')} onClick={onClick}>
      <Icon name="heart" />
    </button>
  );
});
