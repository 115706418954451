import { useStyle } from '../../utils/theme/useStyle';
import { SelectionRules } from './selection.style';
import { memo } from 'react';
import { SkeletonImage } from '../UI/SkeletonImage/SkeletonImage';
import { SkeletonText } from '../UI/SkeletonText/SkeletonText';

const SelectionSkeleton = function SelectionSkeleton() {
  const { css, rules } = useStyle(SelectionRules, {
    marginBottom: 0,
  });

  return (
    <div className={css.skeletonWrapper}>
      <SkeletonText width={'50%'} height={26} extend={{root: rules.skeletonTitle}} />
      <div className={css.skeletonElementsWrapper}>
        <div className={css.skeletonEl}>
          <SkeletonImage width={140} height={140} borderRadius={'8px'} extend={{root: rules.skeletonImage}}/>
          <SkeletonText width={140} height={18} extend={{root: rules.skeletonElTitle}}/>
          <SkeletonText width={100} height={18}/>
        </div>
        <div className={css.skeletonEl}>
          <SkeletonImage width={140} height={140} borderRadius={'8px'} extend={{root: rules.skeletonImage}}/>
          <SkeletonText width={140} height={18} extend={{root: rules.skeletonElTitle}}/>
          <SkeletonText width={100} height={18}/>
        </div>
        <div className={css.skeletonEl}>
          <SkeletonImage width={140} height={140} borderRadius={'8px'} extend={{root: rules.skeletonImage}}/>
          <SkeletonText width={140} height={18} extend={{root: rules.skeletonElTitle}}/>
          <SkeletonText width={100} height={18}/>
        </div>
        <div className={css.skeletonEl}>
          <SkeletonImage width={140} height={140} borderRadius={'8px'} extend={{root: rules.skeletonImage}}/>
          <SkeletonText width={140} height={18} extend={{root: rules.skeletonElTitle}}/>
          <SkeletonText width={100} height={18}/>
        </div>
      </div>
    </div>
  );
};

export default memo(SelectionSkeleton);
