import { FC, memo } from 'react';
// import { SpinnerRules } from './Spinner.style';

interface IProps {
  extend?: string;
}

export const Spinner: FC<IProps> = memo(function Loader(props) {
  const { extend } = props;
  // const { css } = useStyle(SpinnerRules);

  return (
    <div className={[extend, 'lds-spinner'].join(' ')}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
});
