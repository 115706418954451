import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import Modal, { ModalNames } from '../Modal/Modal';
import { modalPromocodeRules } from './modalPromocode.style';
import { Button } from '../UI/Button/Button';
import Text from '../UI/Text/Text';
import { useTheme } from 'src/utils/theme/useTheme';
import { useScrollModal } from 'src/hooks/useScrollModal';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { cartState, promocodeAcquireRequest, promocodeUnacquireRequest, resetNetworkStatuspromocodeAcquire } from 'src/redux/slices/cartSlice';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import { promocodeMaxLength, promocodeMinLength } from 'src/utils/constants';
import useCurrency from 'src/hooks/useCurrency';
import { cartGet } from 'src/redux/api/cart/cartGet';
import { modalErrorState, openErrorModal } from '../../redux/slices/modalErrorSlice';
interface IProps {
  onClose: () => void;
  active: boolean;
  value?: string;
}

const ModalPromocode = function ModalPromocode(props: IProps) {
  const { active, value = '', onClose } = props;
  const [promocode, setPromocode] = useState(value);
  const [incorrectCode, setInorrectCode] = useState<string | undefined>();
  const { css } = useStyle(modalPromocodeRules, { incorrectCode: incorrectCode?.length > 0 });
  const { theme } = useTheme();
  const { t, tPlural } = useTranslation();
  const modalInnerRef = useRef<HTMLDivElement>(null);
  const { networkStatuspromocodeAcquire, errorPromocodeMessage, minPrice } = useAppSelector(cartState)
  const { modalErrorIsOpen } = useAppSelector(modalErrorState);
  const currency = useCurrency();

  const dispatch = useAppDispatch()

  useScrollModal(modalInnerRef);

  useEffect(() => {
    if (modalErrorIsOpen) {
      dispatch(resetNetworkStatuspromocodeAcquire())
    }
  }, [dispatch, modalErrorIsOpen]);

  useEffect(() => {
    switch (true) {
      case errorPromocodeMessage?.includes('UNSPECIFIED'): return setInorrectCode(t('modalPromocode.unspecified')) 
      case errorPromocodeMessage?.includes('EXPIRED'): return setInorrectCode(t('modalPromocode.expired'))
      case errorPromocodeMessage?.includes('MIN_ORDER_AMOUNT'): return setInorrectCode(t('modalPromocode.minimumOrderAmount', `${minPrice} ${currency}`))
      case errorPromocodeMessage?.length > 0: 
        dispatch(openErrorModal({
          errorTitle: errorPromocodeMessage
        }))
        return setInorrectCode('')
    }
  }, [t, dispatch, errorPromocodeMessage, minPrice, currency]);

  const onChangePromocode = event => {
    setInorrectCode(undefined);
    setPromocode(event.target.value);
  };

  const clickKeyboard = useCallback(event => {
    const input = event.currentTarget;
    if (event.key === 'Enter') input.blur();
  }, []);

  const inputRef = useCallback(
    (node: HTMLInputElement) => {
      if (node !== null) {
        node.addEventListener('keypress', clickKeyboard);
      }
    },
    [clickKeyboard],
  );

  const checkPromocode = () => {
    if (!promocode?.length) return;
    if (promocode.length < promocodeMinLength || promocode.length > promocodeMaxLength) {
      const lengthLimit = promocode.length > promocodeMaxLength ? promocodeMaxLength : promocodeMinLength;
      const errorMessageKey = promocode.length > promocodeMaxLength ? 'promoCodeCannotBeMoreThanCharacters' : 'promoCodeCannotBeLessThanCharacters';
      setInorrectCode(tPlural(`modalPromocode.${errorMessageKey}`, lengthLimit));
      return;
    }
    dispatch(promocodeAcquireRequest(promocode));
  };

  const clearPromocode = () => {
    if (value === promocode && promocode) {
      dispatch(promocodeUnacquireRequest(promocode))
    }else {
      setPromocode('')
    }
  };

  useEffect(() => {
    if (networkStatuspromocodeAcquire !== NetworkStatus.Done) return
    dispatch(cartGet())
    dispatch(resetNetworkStatuspromocodeAcquire())
    setTimeout(() => {
      onClose();
    }, 100);
  }, [networkStatuspromocodeAcquire, dispatch, onClose])

  return (
    <Modal
      active={active}
      name={ModalNames.PROMOCODE}
      propsStyles={{ paddingBottom: 30 }}
      onClose={onClose}
    >
      <div ref={modalInnerRef}>
        <div className={css.content}>
          <input
            ref={inputRef}
            placeholder={t('modalPromocode.promoCode')}
            name="promocode"
            type="text"
            className={css.inputPromocode}
            onChange={onChangePromocode}
            value={promocode}
          />
          {incorrectCode?.length > 0 && (
            <Text
              text={incorrectCode}
              mod="text"
              fontSize={12}
              lineHeight={'16px'}
              extend={css.incorrect}
            />
          )}
        </div>
        <footer className={css.footer}>
          <Button
            text={t('modalPromocode.apply')}
            propsStyles={{
              width: '100%',
            }}
            disabled={ !Boolean(promocode) || networkStatuspromocodeAcquire === NetworkStatus.Loading }
            onClick={checkPromocode}
          />
          {value && (
            <Button
              icon="cross"
              disabled={networkStatuspromocodeAcquire === NetworkStatus.Loading}
              propsStyles={{
                background: theme.colorGrey,
                padding: '0',
                width: 62,
                height: 62,
              }}
              onClick={clearPromocode}
              extend={css.btnClear}
            />
          )}
        </footer>
      </div>
    </Modal>
  );
};

export default memo(ModalPromocode);
