import { AllowedProps, IExtend } from './fela.interface';
import { ITheme } from './theme.interface';

export const keyStyleSelector = <Props extends AllowedProps>(
  id: string,
  props?: Props & {
    extend?: IExtend<object>;
    theme?: ITheme;
  },
) => {
  if (props === undefined || Object.keys(props).length === 0) {
    return '--default-style-key--';
  }

  const { theme, extend, ...restProps } = props;

  let propKey = '';

  for (const item in restProps) {
    // @ts-ignore
    if (typeof restProps[item] === 'function') {
      console.error('key is a function', item);
      // @ts-ignore
    } else if (typeof restProps[item] === 'object') {
      console.error('key is an object', item);
    } else {
      // @ts-ignore
      propKey += String(restProps[item]);
    }
  }

  const keys = [id];

  if (extend && Object.keys(extend).length) {
    keys.push(Object.values(extend)[0]?.key);
  }

  // Changing the theme color generates a new key
  if (theme && Object.keys(theme).length) {
    keys.push(theme.mainColor);
  }

  if (propKey) {
    keys.push(propKey);
  }

  return keys.join('+');
};
