import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IFavouritesRuleKeys {
  header: {};
  wrapperProducts: {};
  title: {};
  subtitle: {};
  container: {};
  categoriesWrapper: {};
}

export type ICatalogRules = IRuleFn<IFavouritesRuleKeys>;

const catalogRuleFn: ICatalogRules = () => {
  const headerHeight = 56;

  return {
    container: {
      paddingBottom: 16,
    },
    header: {
      padding: '16px 0',
      display: 'flex',
      alignItems: 'center',
      height: headerHeight,
    },
    wrapperProducts: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      columnGap: '8px',
      rowGap: '16px',
    },
    title: {
      textAlign: 'center',
      opacity: '.5',
      marginBottom: 8,
    },
    subtitle: {
      textAlign: 'center',
      opacity: '.5',
      lineHeight: '16px',
    },
    categoriesWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      paddingBottom: 20,
    }
  };
};

export const catalogRules = createCachedStyle(catalogRuleFn);
