import { FC, memo, useEffect, useRef } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { Story } from './components/Story/Story';
import { storiesRules } from './stories.style';
import { StoriesBlock_ClientStory } from '@teleport/schemas-protobuf';

interface IProps {
  // Refactor and remove
  className?: string;
  propsStyles?: IStyles;

  slides: StoriesBlock_ClientStory[];
}

interface IStyles {
  paddingTop?: number;
  paddingBottom?: number;
}

export const Stories: FC<IProps> = memo(function Stories(props) {
  const { propsStyles, slides } = props;
  const { css } = useStyle(storiesRules, {
    paddingTop: propsStyles?.paddingTop,
    paddingBottom: propsStyles?.paddingBottom,
  });

  const sliderRef = useRef<any>(null);

  // swiper settings
  useEffect(() => {
    const swiperEl = sliderRef.current;

    if (!swiperEl) return;

    const swiperParams = {
      slidesPerView: 'auto',
      spaceBetween: 8,
      slidesOffsetBefore: 16,
      slidesOffsetAfter: 16,
      injectStyles: [
        `.swiper {
          overflow-x: auto;
          scrollbar-width: none;
        }`,
        `.swiper::-webkit-scrollbar: {
          display: none;
        }`,
      ],
    };

    Object.assign(swiperEl, swiperParams);

    swiperEl.initialize();
  }, []);

  return (
    <div className={css.wrapper}>
      <swiper-container ref={sliderRef} init={false}>
        {slides.map(({ story }, i) => (
          // @ts-ignore:next-line
          <swiper-slide class={css.slide} key={story.clickUrl + i}>
            <Story backgroundImage={story.imageUrl} url={story.clickUrl} text={story.text} />
          </swiper-slide>
        ))}
      </swiper-container>
    </div>
  );
});
