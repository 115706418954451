import { ICategoryWidthProducts } from 'src/types/categories';
import { createAppAsyncThunk } from '../../hooks/redux';
import { categoriesTranslator } from '../translators/metadataTranslator';
import { ProductsListTranslator } from '../translators/productsListTranslator';

export const getCatalogMetadataWithProducts = createAppAsyncThunk('getCatalogMetadataWithProducts', async (_, thunkAPI) => {
  const result = await thunkAPI.extra.portApi.catalogMetadata({})

  const categories = categoriesTranslator.fromStatusResponse(result)
  const categoriesWidthProducts: ICategoryWidthProducts[] = []

  for (const category of categories) {

    const params = ProductsListTranslator.toRequestProductsByCategory(category.category.uuid);
    const products = await thunkAPI.extra.portApi.clientCatalogProductList(params);
    const productList = ProductsListTranslator.fromProductsResponse(products);

    categoriesWidthProducts.push({
      ...category,
      products: productList,
    });
  }
  return categoriesWidthProducts;
});
