import { FC, memo, useState } from 'react';
import { IClasses } from 'src/utils/theme/fela.interface';
import { useStyle } from 'src/utils/theme/useStyle';
import Text from 'src/components/UI/Text/Text';
import { Info } from 'src/components/UI/Info/info';
import Toggler from 'src/components/UI/Toggler/Toggler';
import { Price } from 'src/components/Price/Price';
import { modificatorTogglerRules } from './modificatorToggler.style';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { changeChosenModifiers, productState } from 'src/redux/slices/productDetailSlice';
import { ICompoundModifier } from 'src/blocks/Product/components/ProductModificators/ProductModificators';

interface IProps {
  extend?: IClasses;
  modifier: ICompoundModifier
}

export const ModificatorToggler: FC<IProps> = memo(function ModificatorToggler(props) {
  const { extend, modifier } = props;
  const [selected] = useState(false);
  const { css } = useStyle(modificatorTogglerRules, { isSelected: selected });
  const dispatch = useAppDispatch()
  const { chosenModifiers } = useAppSelector(productState)

  function onChange(event) {
    const id = event.currentTarget.id;
    const modifiertoSend = modifier.variants.find(el => el.uuid === id)
    dispatch(changeChosenModifiers({
      uuid: modifiertoSend.uuid,
      title: modifiertoSend.title,
    }))
  }

  return (
    <div>
      <Text
        text={modifier.title}
        mod="title"
        textTransform="uppercase"
        fontSize={12}
        lineHeight="18px"
      />
      {
        modifier.variants.map(variant => {
          const priceImpact = modifier.variants.find(el => el.uuid === variant.uuid)?.priceImpact
          const isChecked = chosenModifiers?.filter(el => el.uuid === variant.uuid).length > 0
          return (
            <div className={[css.modificator, extend].join(' ')} key={variant.uuid}>
              <div className={css.info}>
                <Text text={variant.title} mod="title" textTransform="uppercase" fontSize={12} />
                {modifier.descriptionLink && <Info href={modifier.descriptionLink} />}
              </div>
              <div className={css.togglerWrapper}>
                { priceImpact > 0 && isChecked && (
                  <Price amount={priceImpact} hasSymbol isPositive fontSize={12} lineHeight={'18px'} fontWeight={700} extend={css.price} />
                )}
                <Toggler id={variant.uuid} name={variant.title} value={variant.uuid} onChange={onChange} checked={isChecked} />
              </div>
            </div>
          )
        })
      }
    </div>

  );
});
