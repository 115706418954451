import { IRuleFn } from '../../utils/theme/fela.interface';
import { createCachedStyle } from '../../utils/theme/createCachedStyle';

export interface IsearchRuleKeys {
  searchButton: {};
  searchInput: {};
  searchIcon: {};
  searchResult: {};
  searchResultInner: {};
  searchClose: {};
  resultEl: {};
  spinnerWrapper: {};
}

interface ISearchRuleProps {
  open: boolean;
}

export type ISearchRules = IRuleFn<IsearchRuleKeys, ISearchRuleProps>;

const searchRuleFn: ISearchRules = props => {
  const { colorWhite, colorBlack, colorDarkGrey, colorGrey, background, appMaxWidth } = props.theme;
  const { open } = props;

  colorGrey

  return {
    searchButton: {
      '& svg': {
        fill: colorBlack
      }
    },
    searchInput: {
      position: 'relative',
      maxWidth: `calc(${appMaxWidth}px - 32px)`,
      height: 38,
      marginBottom: 12,

      '> input': {
        textDecoration: 'none',
        background: colorWhite,
        padding: '8px 36px 8px',
        border: 'none',
        outline: 'none',
        borderRadius: 8,
        width: '100%',
        color: colorBlack,
        height: '100%',
        fontSize: 16,
        lineHeight: '22px',
        fontWeight: 500,
        '::placeholder': {
          color: colorDarkGrey
        }
      },
    },
    searchIcon: {
      width: 20,
      height: 20,
      pointerEvents: 'none',
      position: 'absolute',
      zIndex: 2,
      top: '50%',
      left: '8px',
      transform: 'translateY(-50%)',
      fill: colorDarkGrey,
    },
    searchResult: {
      backgroundColor: background,
      transition: 'transform .3s linear',
      padding: '16px',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100vh',
      transform: open ? 'translateY(0)' : 'translateY(-100%)',
      zIndex: 20,
    },
    searchResultInner: {
      maxWidth: appMaxWidth,
      height: 'calc(100% - 82px)',
      width: '100%',
      margin: '0 auto',
    },
    spinnerWrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    searchClose: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: '8px',
      width: 20,
      height: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: background,
      borderRadius: '50%',
      outline: 'none',
      border: 'none',
    },
    resultEl: {
      display: 'block',
      padding: '8px 0',
      borderBottom: '1px solid rgba(28, 25, 22, 0.1)',

      // txt
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '22px',
    },
  };
};

export const searchRules = createCachedStyle(searchRuleFn);
