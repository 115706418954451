import { RootCategory, SubCategory } from '@teleport/schemas-protobuf';
import { createAppAsyncThunk } from '../../hooks/redux';
import {IGetProductsListParams} from './getProductsList';

export interface IGetProductsBySearch {
  query: string;
  category?: RootCategory;
  subCategory?: SubCategory;
  selectionUuid?: string;
}

export const getProductsBySearch = createAppAsyncThunk(
  'search/getProductsBySearch',
  async (params: IGetProductsListParams, thunkAPI) => {
    return await thunkAPI.extra.portApi.clientCatalogProductList(params);
  }
);
