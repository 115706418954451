import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModificatorOptionsRuleKeys {
  header: {};
  descriptionLink: {};
  options: {};
  info: {};
  error: {};
  // price: {};
}

export type IModificatorOptionsRules = IRuleFn<IModificatorOptionsRuleKeys>;

const modificatorOptionsRuleFn: IModificatorOptionsRules = props => {
  const { colorRed } = props.theme;

  return {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '4px',
    },
    options: {
      display: 'flex',
      gap: '8px',
    },
    descriptionLink: {},
    info: {
      display: 'flex',
      alignItems: 'center',
      columnGap: '9px',
    },
    error: {
      color: colorRed,
      fontSize: 12,
      fontWeight: 500,
      paddingTop: 4,
    }
    // price: {},
  };
};

export const modificatorOptionsRules = createCachedStyle(modificatorOptionsRuleFn);
