import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { MakeOrderFormRules } from './MakeOrderForm.style';
import { Button } from '../UI/Button/Button';
import { Input } from '../UI/Input/Input';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import Container from '../Container/Container';
import Text from '../UI/Text/Text';
import { useModalState } from 'src/hooks/useModalState';
import { ModalResult } from '../ModalResult/ModalResult';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {configState} from '../../redux/slices/configSlice';
import { ClientModifier, DeliveryType } from '@teleport/schemas-protobuf';
import {directCheckout} from '../../redux/api/directCheckout';
import {checkoutState, resetNetworkStatus} from '../../redux/slices/checkoutSlice';
import {NetworkStatus} from '../../utils/connect/connectConstant';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input/input';
import {useTheme} from '../../utils/theme/useTheme';
import {useTranslation} from '../../utils/i18n/hooks/useTranslation';
import { cartCheckout } from '../../redux/api/cart/cartCheckout';
import { productState } from '../../redux/slices/productDetailSlice';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { RoutePath } from '../../providers/AppRouter/routeConfig';

interface IOrderedProduct {
  uuid: string;
  price: number;
}

interface IProps {
  product?: IOrderedProduct;
  customerInitialData?: IFormMakeOrder
}

interface IFormMakeOrder {
  name: string;
  phone: string;
  email?: string;
}

const phoneNumberRegex = new RegExp('^\\+[0-9]{1,3}[0-9]+$')

export const MakeOrderForm: FC<IProps> = memo(function MakeOrderForm(props) {
  const { product, customerInitialData } = props;
  const isDirectCheckout = Boolean(product);
  const [footerHeight, setFooterHeight] = useState(0);
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const { css } = useStyle(MakeOrderFormRules, {
    footerHeight: footerHeight,
  });
  const {theme} = useTheme()
  const dispatch = useAppDispatch();
  const { networkStatus, checkoutErrorMessage } = useAppSelector(checkoutState)
  const { config } = useAppSelector(configState);
  const { chosenModifiers } = useAppSelector(productState)
  const {t} = useTranslation();
  const navigate = useNavigate();

  const closeModalFunction = () => {
    dispatch(resetNetworkStatus('networkStatus'));
    closeModal()
    setTimeout(() => {
      if (networkStatus === NetworkStatus.Done ) {
        dispatch(resetNetworkStatus('formNetworkStatus'))
        navigate('/');
      }
    }, 100);
  }

  const buttonText = t('makeOrderForm.placeOrder');
  const {productId} = useParams();

  useEffect(() => {
    if (networkStatus === NetworkStatus.Failed && checkoutErrorMessage.includes('CART_ERROR_OUTDATED')) {
      if(isDirectCheckout) {
        navigate(generatePath(RoutePath.product, {productId}))
      } else {
        navigate(generatePath(RoutePath.cart))
      }
      dispatch(resetNetworkStatus('networkStatus'));
    }
    if(networkStatus === NetworkStatus.Done || networkStatus === NetworkStatus.Failed) {
      openModal();
    }
  }, [networkStatus, checkoutErrorMessage, isDirectCheckout, navigate, productId, dispatch, openModal]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setError,
  } = useForm({
    defaultValues: {
      name: customerInitialData?.name? customerInitialData?.name : '',
      email: customerInitialData?.email? customerInitialData?.email : '',
      phone: customerInitialData?.phone? customerInitialData?.phone : '',
    },
  });

  const onSubmit: SubmitHandler<IFormMakeOrder> = data => {

    if (!isPossiblePhoneNumber(data.phone) || !phoneNumberRegex.test(data.phone)) {
      setError('phone', {message: t('makeOrderForm.checkTheValidityOfTheNumber')})
      return;
    }

    if(data) {
      if(isDirectCheckout) {
        dispatch(directCheckout({product: {
          productUuid: productId,
          quantity: 1,
          chosenModifiers: chosenModifiers.map(el => (new ClientModifier({modifierVariantUuid: el.uuid, modifierVariantTitle: el.title}))),
        }, formData: data}))
      }
      else {
        dispatch(cartCheckout({formData: data}))
      }
    }
  };

  const createOnChangePhoneFn = (onChangeCallBack: (...event: any[]) => void) => {
    return function (value: string | undefined) {
      if(value === undefined) {
        onChangeCallBack('')
      } else {
        onChangeCallBack(value)
      }
    }
  }

  const footerRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setFooterHeight(node.clientHeight);
    }
  }, []);

  return (
    <>
      <form className={css.wrapper} onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <div className={css.fieldWrapper}>
            <Input
              {...register('name', {required: t('makeOrderForm.pleaseFillInTheField')})}
              controlled={false}
              type="text"
              label={t('makeOrderForm.name')}
              placeholder={t('makeOrderForm.enterYourName')}
              maxLength={200}
              errorMessage={errors.name?.message}
            />
          </div>
          <div className={css.fieldWrapper}>
            <Text text={t('makeOrderForm.phoneNumber')} mod="text" fontSize={12} extend={css.label} />
            <Controller
              rules={{
                required: t('makeOrderForm.pleaseFillInTheField'),
              }}
              control={control}
              name='phone'
              render={({ field: { onChange, value, } }) => (
                <PhoneInput
                  defaultCountry={'RU'}
                  onChange={createOnChangePhoneFn(onChange)}
                  value={value}
                  placeholder={t('makeOrderForm.enterYourPhoneNumber')}
                  className={css.input}
                  style={{borderColor: Boolean(errors.phone?.message) ? theme.colorRed : 'transparent'}}
                />
              )}
            />
            {errors.phone?.message && <Text
              text={errors.phone?.message}
              mod='text'
              fontSize={12}
              lineHeight='14px'
              fontWeight={500}
              extend={css.inputErrorMessage}
            />}
          </div>
          {config.deliveryType !== DeliveryType.REQUEST
            && <div className={css.fieldWrapper}>
              <Input
                {...register('email')}
                controlled={false}
                type="text"
                label={t('makeOrderForm.email')}
                placeholder={t('makeOrderForm.enterYourEmail')}
                maxLength={200}
                errorMessage={errors.email?.message}
              />
            </div>}
          <div className={css.footer} ref={footerRef}>
            <Button propsStyles={{width: '100%', height: 62}} hasGradient={true}>
              <div>
                <Text text={buttonText} mod="text" fontSize={16} fontWeight={700} lineHeight="18px"/>
              </div>
            </Button>
          </div>
        </Container>
      </form>
      {renderModal && (
        <ModalResult
          onClose={closeModalFunction}
          active={activeModal}
          success={networkStatus !== NetworkStatus.Failed}
          title={networkStatus === NetworkStatus.Failed ? t('makeOrderForm.noOrderPlaced') : t('makeOrderForm.orderPlaced')}
          text={networkStatus === NetworkStatus.Failed ? t('makeOrderForm.somethingWentWrong') : t('makeOrderForm.aPaymentLinkWillBeSentToYouInTheBot')}
        />
      )}
    </>
  );
});
