import { FC, memo } from 'react';
import { useStyle } from '../../utils/theme/useStyle';
import { categoryCardRules } from './СategoryCard.style';
import { SkeletonText } from '../UI/SkeletonText/SkeletonText';
import { SkeletonImage } from '../UI/SkeletonImage/SkeletonImage';

export const CategoryCardSkeleton: FC = memo(function CategoryCardSkeleton() {
  const { css, rules } = useStyle(categoryCardRules);

  return (
    <div className={css.card}>
      <div className={css.skeletonGrid}>
        <SkeletonImage width={48} height={48} borderRadius={'8px'} />
        <SkeletonImage width={48} height={48} borderRadius={'8px'} />
        <SkeletonImage width={48} height={48} borderRadius={'8px'} />
        <SkeletonImage width={48} height={48} borderRadius={'8px'} />
      </div>
      <div>
        <SkeletonText width={'150px'} height={16} extend={{root: rules.skeletonTitle}} />
        <SkeletonText width={'100px'} height={16} />
      </div>
    </div>
  );
});
