import { StatusResponse } from '@teleport/schemas-protobuf';

export const MainTranslator = {
  fromStatusResponse(response: StatusResponse) {
    return {
      blocks: response?.mainPageBlocks ?? [],
      hasMaintenance: response?.maintenance?.enabled,
      maintenance: {
        reasonText: response?.maintenance?.reasonText,
        supportLink: response?.maintenance?.supportLink,
      },
    };
  },
};
