import type { PropsWithChildren, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { buttonRules } from './button.styles';
import { Icon, IconList } from 'src/components/Icon/Icon';

interface ButtonProps {
  text?: string;
  mod?: 'icon';
  icon?: IconList;
  iconAtTheEnd?: boolean;
  href?: string;
  onClick?: (e: React.SyntheticEvent) => void;
  children?: ReactNode;
  active?: boolean;
  disabled?: boolean;
  hasGradient?: boolean;
  // styles
  extend?: any;
  propsStyles?: IStyles;
}

export interface IStyles {
  width?: string | number;
  height?: string | number;
  padding?: string | number;
  background?: string;
  borderRadius?: string | number;
  color?: string;
}

export const Button = memo(function Button(props: PropsWithChildren<ButtonProps>) {
  const {
    text,
    propsStyles,
    icon,
    iconAtTheEnd,
    href,
    extend,
    onClick,
    children,
    disabled,
    hasGradient,
    active,
  } = props;
  const { css } = useStyle(buttonRules, {
    width: propsStyles?.width,
    height: propsStyles?.height,
    padding: propsStyles?.padding,
    background: propsStyles?.background,
    color: propsStyles?.color,
    borderRadius: propsStyles?.borderRadius,
    iconAtTheEnd,
    hasGradient,
    active,
    disabled,
  });

  if (href) {
    return (
      <Link className={[css.button, extend].join(' ')} to={href}>
        {text && <span className={css.text}>{text}</span>}
        {children}
        {icon && <Icon name={icon} properties={{ className: css.icon }} />}
      </Link>
    );
  }
  return (
    <button className={[css.button, extend].join(' ')} onClick={onClick}>
      {text && <span className={css.text}>{text}</span>}
      {children}
      {icon && <Icon name={icon} properties={{ className: css.icon }} />}
    </button>
  );
});
