import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { priceRules } from './price.style';
import useCurrency from 'src/hooks/useCurrency';
import { telegramStarsString } from 'src/utils/constants';

interface CommonProps {
  amount: number | bigint;
  fontSize: number,
  lineHeight: string,
  fontWeight: number,
  color?: string,
  extend?: string;
  oldPrice?: boolean;
}

type ConditionalProps =
  | {
      hasSymbol: true;
      isPositive: boolean;
    }
  | { hasSymbol: false; isPositive?: never };

type IProps = CommonProps & ConditionalProps;

export const Price: FC<IProps> = memo(function Price(props) {
  const { amount, isPositive, hasSymbol, fontSize, lineHeight, fontWeight, extend, color, oldPrice } = props;
  const { css } = useStyle(priceRules, { oldPrice, fontSize, fontWeight, lineHeight, color });

  const currency= useCurrency()

  let symbol = '';

  if (hasSymbol && isPositive) {
    symbol = '+';
  } else if (hasSymbol && !isPositive) {
    symbol = '-';
  }

  return (
    <div className={[css.wrapper, extend].join(' ')}>
      <p className={css.price}>
        {symbol + Number(amount).toLocaleString('ru')}
      </p>
      {currency === telegramStarsString ? <img className={css.currencyImg} src={`/images/currencies/${currency}.svg`} alt='' /> : currency}
    </div>
  );
});
