import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IProductMainInfoKeys {
  header: {};
  category: {};
  shareBtn: {};
  priceWrapper: {};
  skeletonHeader: {};
}

export type IProductMainInfos = IRuleFn<IProductMainInfoKeys>;

const productMainInfoRuleFn: IProductMainInfos = props => {
  const { mainColor } = props.theme;

  return {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 16,
    },

    category: {
      color: mainColor,
      fontSize: 12,
      fontWeight: '600',
    },

    priceWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },

    shareBtn: {
      marginTop: '24px',
      marginBottom: 0,
    },
    skeletonHeader: {
      marginBottom: 8,
    },
  };
};

export const productMainInfoRules = createCachedStyle(productMainInfoRuleFn);
