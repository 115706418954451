import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IInfoRuleKeys {
  info: {};
}

export type IInfoRules = IRuleFn<IInfoRuleKeys>;

const infoRuleFn: IInfoRules = () => ({
  info: {
    display: 'block',
    width: '20px',
    height: '20px',
    padding: '1px',

    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
});

export const infoRules = createCachedStyle(infoRuleFn);
