export interface ITheme {
  background: string;
  colorBlack: string;
  colorRgbBlack: string;
  colorBlue: string;
  mainColor: string;
  colorRed: string;
  colorWhite: string;
  colorRgbWhite: string;
  colorGrey: string;
  colorDarkGrey: string;
  gradientBlue: string;
  colorDarkGreen: string;

  // rgb
  colorRgbMain: string;

  appMaxWidth: number;
  appFooterHeight: number;
  defaultFont: string;
  transitionDuration: string;
}

export enum ThemeName {
  Dark = 'dark',
  Light = 'light',
}
