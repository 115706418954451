import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface ILayoutRuleKeys {
  root: {};
  main: {};
}

interface ILayoutRuleProps {
  mainFlexColumn?: boolean;
}

export type ILayoutRules = IRuleFn<ILayoutRuleKeys, ILayoutRuleProps>;

const layoutRuleFn: ILayoutRules = props => {
  // Размер шрифта и прочие приколы поместить props.theme
  // Ни одного файла css
  // Если нужен какой-то конкретный стиль например container, то создаём под него компонент

  const { appMaxWidth, background } = props.theme;
  const { mainFlexColumn } = props;

  return {
    root: {
      maxWidth: appMaxWidth,
      margin: '0 auto',
      background: background,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      height: '100vh',
      overflowY: 'scroll',
    },
    main: {
      flex: '1 0 auto',
      display: mainFlexColumn ? 'flex' : 'block',
      flexDirection: mainFlexColumn ? 'column' : 'none',
    },
  };
};

export const layoutRules = createCachedStyle(layoutRuleFn);
