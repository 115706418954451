import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface INothingWasFoundKeys {
  wrapper: {}
  title: {}
  resetButton: {}
}

export type INothingWasFound = IRuleFn<INothingWasFoundKeys>;

const NothingWasFoundRuleFn: INothingWasFound = props => {
  const {} = props.theme;
  const {} = props;

  return {
    wrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      opacity: .5,
      marginBottom: 8,
    },
    resetButton: {
      marginTop: 24,
    }
  }

}

export const NothingWasFoundRules = createCachedStyle(NothingWasFoundRuleFn);