import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface ICartRuleKeys {
  header: {};
  wrapperProducts: {};
  noCart: {};
  title: {};
  subtitle: {};
  cards_wrapper: {};
  card: {};
  cart_info: {};
  cart_wrapper: {};
  summary_row: {};
  total_amount: {};
  to_pay: {};
  summary_row_inner: {};
  total_disc: {};
  promocodeName: {};
  promocodeVal: {};
}

export type ICartRules = IRuleFn<ICartRuleKeys>;

const cartRuleFn: ICartRules = props => {
  const { colorBlack, colorWhite, colorRgbBlack, colorBlue } = props.theme;

  const headerHeight = 56;

  return {
    header: {
      padding: '16px 0',
      display: 'flex',
      alignItems: 'center',
      height: headerHeight,
    },
    cart_wrapper: {
      flexGrow: '1',
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'column',
      height: '100dvh',
      overflow: 'hidden',
    },
    wrapperProducts: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      columnGap: '8px',
      rowGap: '16px',
    },
    noCart: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    title: {
      textAlign: 'center',
      opacity: '.5',
      marginBottom: 8,
    },
    subtitle: {
      textAlign: 'center',
      opacity: '.5',
      lineHeight: '16px',
    },
    card: {
      position: 'relative',

      '::before': {
        position: 'absolute',
        display: 'block',
        content: '\'\'',
        width: '100%',
        height: '1px',
        backgroundColor: colorBlack,
        opacity: '.1',
        top: '0',
        left: 16,
      },

      ':first-child': {
        '::before': {
          display: 'none',
        },
      },
    },

    cards_wrapper: {
      overflowY: 'auto',
    },

    cart_info: {
      background: colorWhite,
      flexShrink: '0',
      paddingBottom: '46px',
      paddingTop: '16px',
      borderRadius: '16px 16px 0 0',
    },
    summary_row: {
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '-0.4px',
      fontWeight: '400',
      padding: '12px 0',
      borderBottom: `1px solid rgba(${colorRgbBlack}, 0.1)`,
    },
    summary_row_inner: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '10px',
    },
    total_amount: {
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing: '-0.14px',
    },
    to_pay: {
      fontSize: '20px',
      lineHeight: '22px',
      fontWeight: '800',
      letterSpacing: '-0.4px',
      paddingTop: '16px',
      paddingBottom: '36px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '10px',
    },
    total_disc: {
      color: colorBlue,
    },
    promocodeName: {
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '22px',
      letterSpacing: '-0.4px',
      color: colorBlue,
    },
    promocodeVal: {
      fontSize: '12px',
      fontWeight: '500',
      lineHeight: '16px',
      letterSpacing: '-0.4px',
      color: colorBlack,
      opacity: '0.4',
    },
  };
};

export const cartRules = createCachedStyle(cartRuleFn);
