import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModalResultKeys {
  content: {};
  footer: {};
  text: {};
}

export type IModalResult = IRuleFn<IModalResultKeys>;

const ModalResultRuleFn: IModalResult = props => {
  const {} = props.theme;
  const {} = props;

  return {
    content: {
      position: 'relative',
      padding: '40px 0 40px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      // gap: '28px',
      alignItems: 'center',
    },
    footer: {
      // position: 'fixed',
      // bottom: 0,
      // left: 0,
      // backgroundColor: colorWhite,
      // width: '100%',
      // padding: '12px 16px 46px',
      display: 'flex',
      columnGap: '12px',
    },
    text: {
      fontWeight: '800',
      fontSize: '20px',
      lineHeight: '26px',
    },
  };
};

export const ModalResultRules = createCachedStyle(ModalResultRuleFn);
