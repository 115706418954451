import { memo, useCallback } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { useTheme } from 'src/utils/theme/useTheme';
// import { useAppSelector } from 'src/hooks/redux';
import { Button } from '../UI/Button/Button';
import { headerRules } from './header.style';
import Container from '../Container/Container';
import { StoreInfo } from '../StoreInfo/StoreInfo';
import Search from '../Search/Search';
import { useAppSelector } from 'src/hooks/redux';
import { configState } from 'src/redux/slices/configSlice';
import { generateStoreLink } from '../../utils/url/generateStoreLink';
import { useLocation } from 'react-router-dom';

interface IProps {
  extend?: string;
  showFavoriteBtn?: boolean;
  openSortModalFn?: () => void;
  openModalFitersFn?: () => void;
}

const Header = function Header(props: IProps) {
  const { extend } = props;
  const { css } = useStyle(headerRules);
  const { theme } = useTheme();
  const { pathname } = useLocation();
  const { config: { portBotUsername, codeName } } = useAppSelector(configState);
  const currentUrl = generateStoreLink(codeName, portBotUsername, pathname.substring(1));

  const shareProduct = useCallback(() => {
    navigator
      .share({ url: currentUrl })
      .catch(reason => console.info('Sharing failed: ', reason));
  }, [currentUrl]);

  return (
    <header className={[css.header, extend].join(' ')}>
      <Container extend={css.headerInner}>
        <StoreInfo/>
        <div className={css.buttons}>
          <Search/>
          <Button
            icon="share"
            propsStyles={{ padding: 9, width: 38, height: 38, background: theme.colorGrey, borderRadius: 8 }}
            onClick={shareProduct}
            extend={css.shareButton}
          />
        </div>
      </Container>
    </header>
  );
};

export default memo(Header);
