import { useContext } from 'react';
import { RendererContext } from 'react-fela';
import { IRenderer as IFelaRenderer } from 'fela';

type IRenderer = IFelaRenderer & {
  prettySelectors?: boolean;
};

export const useRenderer = () => {
  const renderer = useContext<IRenderer>(RendererContext);

  return {
    renderer,
  };
};
