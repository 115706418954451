import { memo, useCallback } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { filtersState, setSorting } from '../../redux/slices/filterSlice';
import { modalSortingRules } from './modalSorting.style';
import Modal, { ModalNames } from '../Modal/Modal';
import Chip from '../UI/Chip/Chip';
import Text from '../UI/Text/Text';
import { getProductsList } from '../../redux/api/getProductsList';
import { Sorting } from '@teleport/schemas-protobuf';
import { FilterTranslator } from 'src/redux/translators/filterTranslator';
import { getSearchValue } from 'src/redux/slices/searchSlice';
import { categoriesState } from 'src/redux/slices/categoriesSlice';
import { useParams } from 'react-router-dom';
import {useTranslation} from '../../utils/i18n/hooks/useTranslation';

interface IProps {
  onClose: () => void;
  active: boolean;
}

const ModalSorting = function ModalSorting(props: IProps) {
  const { onClose, active } = props;
  const { css } = useStyle(modalSortingRules);
  const dispatch = useAppDispatch();
  const selectedSorting = useAppSelector(state => state.filters.selectedSorting);
  const { filters, selectedFilters } = useAppSelector(filtersState);
  const searchValue = useAppSelector(getSearchValue)
  const { t } = useTranslation();
  // const { networkStatus } = useAppSelector(productsState);
  const { selectedCategory, selectedSubCategory } = useAppSelector(categoriesState);
  const { selectionId } = useParams();

  // descent - спускаться;
  // ascend - восходить;

  const getSortingText = useCallback((sortingNumber: Sorting) => {
    switch (sortingNumber) {
      case Sorting.POPULARITY:
        return t('modalSorting.byPopularity');
      case Sorting.DISCOUNT_DESC:
        return  t('modalSorting.byDiscountSize');
      case Sorting.PRICE_ASC:
        return t('modalSorting.priceLowToHigh');
      case Sorting.PRICE_DESC:
        return t('modalSorting.priceHighToLow');
      default:
        return 'Default';
    }
  }, [t]);
  
  const requestNewProductsList = useCallback((sortingNumber: Sorting) => {
    const filtersForRequest = []

    if(selectedFilters.length > 0) {
      filters.forEach(filter => filtersForRequest.push(filter))
    }

    if (searchValue.length >= 3) {
      filtersForRequest.push(FilterTranslator.toSearchFilter(searchValue))
    }

    if (selectedCategory) {
      filtersForRequest.push(FilterTranslator.toCategoryFilter(selectedCategory))
    }

    if (selectedSubCategory) {
      filtersForRequest.push(FilterTranslator.toSubCategoryFilter(selectedSubCategory))
    }

    if (selectionId) {
      filtersForRequest.push(FilterTranslator.toSelectionFilter(selectionId))
    }

    dispatch(getProductsList({ 
      filters: filtersForRequest, 
      sorting: sortingNumber
    }));
  }, [
    dispatch, 
    filters, 
    searchValue, 
    selectedCategory,
    selectedSubCategory, 
    selectionId,
    selectedFilters
  ])

  const handleSetSorting = value => {
    const sortingNumber = Number(value);
    const sortingText = getSortingText(sortingNumber);
    dispatch(setSorting({ sortingNumber, text: sortingText }));
    requestNewProductsList(sortingNumber);
    onClose();
  };

  return (
    <Modal active={active} name={ModalNames.SORT} onClose={onClose}>
      <div className={css.header}>
        <Text text={t('modalSorting.sort')} mod="title" />
      </div>
      <div className={css.content}>
        <Chip
          text={t('modalSorting.default')}
          name="sort-filter"
          value="0"
          type="radio"
          checked={selectedSorting?.sortingNumber === Sorting.UNSPECIFIED}
          onChange={e => handleSetSorting(e.target.value)}
        />
        <Chip
          text={t('modalSorting.byPopularity')}
          name="sort-filter"
          value="1"
          type="radio"
          checked={selectedSorting?.sortingNumber === Sorting.POPULARITY}
          onChange={e => handleSetSorting(e.target.value)}
        />
        <Chip
          text={t('modalSorting.byDiscountSize')}
          name="sort-filter"
          value="2"
          type="radio"
          checked={selectedSorting?.sortingNumber === Sorting.DISCOUNT_DESC}
          onChange={e => handleSetSorting(e.target.value)}
        />
        <Chip
          text={t('modalSorting.priceLowToHigh')}
          name="sort-filter"
          value="3"
          type="radio"
          checked={selectedSorting?.sortingNumber === Sorting.PRICE_ASC}
          onChange={e => handleSetSorting(e.target.value)}
        />
        <Chip
          text={t('modalSorting.priceHighToLow')}
          name="sort-filter"
          value="4"
          type="radio"
          checked={selectedSorting?.sortingNumber === Sorting.PRICE_DESC}
          onChange={e => handleSetSorting(e.target.value)}
        />
      </div>
    </Modal>
  );
};

export default memo(ModalSorting);
