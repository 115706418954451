import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IModalFiltersRuleKeys {
  content: {};
  toggle: {};
  enumInner: {};
  filterEl: {};
  footer: {};
  title: {};
  titleMargin: {};
  header: {};
  modalInner: {};
}

interface IModalFiltersRuleProps {
  headerHeight: number;
}

export type IModalFiltersRules = IRuleFn<IModalFiltersRuleKeys, IModalFiltersRuleProps>;

const modalFiltersRuleFn: IModalFiltersRules = props => {
  const { colorWhite, appFooterHeight } = props.theme;
  const { headerHeight } = props;

  return {
    content: {},
    filterEl: {
      paddingTop: 12,
      paddingBottom: 12,
    },
    toggle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    enumInner: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 8,
      maxHeight: '120px',
      overflow: 'hidden',
    },
    title: {
      ':first-letter': {
        textTransform: 'uppercase',
      },
    },
    titleMargin: { marginBottom: 8 },
    header: {
      paddingTop: 20,
      paddingBottom: 12,
    },
    modalInner: {
      maxHeight: `calc(100% - ${headerHeight}px)`,
      overflow: 'auto',
    },
    footer: {
      backgroundColor: colorWhite,
      height: appFooterHeight,
      padding: '12px 0',
      position: 'sticky',
      bottom: 0,
    },
  };
};

export const modalFiltersRules = createCachedStyle(modalFiltersRuleFn);
