import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IModalRuleKeys {
  modal: {};
  modalContent: {};
  btnClose: {};
  // modalInner: {};
  closeBar: {};
}

export interface IModalRuleProps {
  active: boolean;
  paddingTop: number;
  paddingBottom: number;
  paddingLeft: number;
  paddingRight: number;
  titleHeight: number;
}

export type IModalRules = IRuleFn<IModalRuleKeys, IModalRuleProps>;

const modalRuleFn: IModalRules = props => {
  const { colorRgbBlack, colorWhite, colorBlack } = props.theme;
  const {
    active,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    // titleHeight,
  } = props;

  return {
    modal: {
      width: '100vw',
      height: '100vh',
      position: 'fixed',
      zIndex: 100,
      top: 0,
      left: 0,
      backgroundColor: `rgba(${colorRgbBlack}, .5)`,
      transitionDuration: '0.3s',
      transitionTimingFunction: 'linear',
      transitionProperty: 'opacity, visibility',
      visibility: active ? 'visible' : 'hidden',
      opacity: active ? 1 : 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    modalContent: {
      borderRadius: '16px 16px 0 0',
      position: 'relative',
      paddingTop: paddingTop ?? 16,
      paddingLeft: paddingLeft ?? 16,
      paddingRight: paddingRight ?? 16,
      paddingBottom: paddingBottom ?? 58,
      transitionDuration: '0.1s',
      transitionTimingFunction: 'linear',
      transitionProperty: 'transform',
      userSelect: 'none',
      touchAction: 'none',
      opacity: 1,
      width: '100%',
      height: 'auto',
      maxHeight: 'calc(100vh - 120px)',
      backgroundColor: colorWhite,
      transform: active ? 'translateY(0)' : 'translateY(100%)',
    },
    // modalInner: {
    //   // touchAction: "none",
    //   maxHeight: `calc(100% - ${titleHeight}px)`,
    //   overflow: "auto",
    // },
    btnClose: {},
    closeBar: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: 36,
      paddingTop: '5.5px',

      '::before': {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        content: '\'\'',
        width: '50px',
        height: '5px',
        backgroundColor: colorBlack,
        borderRadius: '16px',
        opacity: '.5',
      },
    },
  };
};

export const modalRules = createCachedStyle(modalRuleFn);
