import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IModalSortingRuleKeys {
  content: {};
  header: {};
}

export type IModalSortingRules = IRuleFn<IModalSortingRuleKeys>;

const modalSortingRuleFn: IModalSortingRules = props => {
  const {} = props.theme;

  return {
    header: {
      paddingTop: 20,
      paddingBottom: 20,
    },
    content: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '8px',
    },
  };
};

export const modalSortingRules = createCachedStyle(modalSortingRuleFn);
