import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IEmptyCartRuleKeys {
  wrp: {};
  img: {};
  title: {};
}

export type IEmptyCartRules = IRuleFn<IEmptyCartRuleKeys>;

const emptycartRuleFn: IEmptyCartRules = props => {
  const { mainColor } = props.theme;

  return {
    wrp: {
      textAlign: 'center',
      flex: '1 0 auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    img: {
      display: 'block',
      width: '118px',
      height: '118px',
      objectFit: 'contain',
      objectPosition: 'center',
      marginBottom: '27px',
      fill: mainColor,
    },
    title: {
      marginBottom: '8px',
    },
  };
};

export const emptycartRules = createCachedStyle(emptycartRuleFn);
