import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IPriceRuleKeys {
  wrapper: {};
  price: {};
  currencyImg: {};
}

interface IPriceRuleProps {
  oldPrice: boolean;
  fontSize: number;
  fontWeight: number;
  lineHeight: string;
  color: string;
}



export type IPriceRules = IRuleFn<IPriceRuleKeys, IPriceRuleProps>;

const priceRuleFn: IPriceRules = props => {
  const { oldPrice, fontSize, fontWeight, lineHeight, color } = props;
  const { colorDarkGrey, colorBlack } = props.theme;

  return {
    wrapper: {
      display: 'inline-flex',
      alignItems: 'center',
      columnGap: 2,
      fontSize,
      fontWeight,
      lineHeight,
      whiteSpace: 'nowrap',
      color: color ? color : colorBlack,
    },
    price: {
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      position: 'relative',
      padding: oldPrice ? '0px 4px' : 0,

      ':before': {
        content: '""',
        display: oldPrice ? 'block' : 'none',
        height: 2,
        backgroundColor: colorDarkGrey,
        width: '100%',
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
      },
    },
    currencyImg: {
      width: fontSize * 1.42,
    },
  };
};

export const priceRules = createCachedStyle(priceRuleFn);
