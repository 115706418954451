import { base64URLEncode } from './base64URLEncode';

export const generateStoreLink = (portCodename: string, portBotUsername: string, path = '', paramsObject: Record<string, string> = {}): string => {
  const params = Object.entries(paramsObject).map(([key, value]) => {
    return (`${key}=${value}`);
  }).concat(['eol']).join('&');

  const tgStartAppParam = base64URLEncode(`teleport-port://${portCodename}/${path}?${params}`);
  return `https://t.me/${portBotUsername}/port?startapp=${tgStartAppParam}`;
};
