import { IRuleFn } from '../../utils/theme/fela.interface';
import { createCachedStyle } from '../../utils/theme/createCachedStyle';

export interface IHeaderRuleKeys {
  header: {};
  headerBtn: {};
  headerInner: {};
  shareButton: {}
  buttons: {}
}

export type IHeaderRules = IRuleFn<IHeaderRuleKeys>;

const headerRuleFn: IHeaderRules = props => {
  const { colorBlack } = props.theme;

  return {
    header: {
      paddingTop: 12,
      paddingBottom: 12,
    },
    headerInner: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      columnGap: 10,
    },
    headerBtn: {
      '& svg': {
        '& .border': {
          fill: colorBlack,
        },
        fill: colorBlack,
      },
    },
    buttons: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
    },
    shareButton: {
      '& svg': {
        width: '100%',
        height: '100%'
      }
    }
  };
};

export const headerRules = createCachedStyle(headerRuleFn);
